import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footerthree from "../layouts/Footerthree";
import Content from "../sections/pricing/Content";
import { useTranslation } from "react-i18next";
import Categories from "../sections/homefour/Categories";

const Pricing = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Pricing</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("Buy") }} />
      <Categories type='Sell' />
      <Content />
      <Footerthree />
    </Fragment>
  );
};

export default Pricing;
