import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown, NavLink } from "react-bootstrap";
import Listingmap from "./Listingmap";
import classNames from "classnames";
import {  withTranslation } from "react-i18next";
import { Select } from "antd";
import axios from "axios";
import defaultpic from "../../../assets/DefaultPic/profile.png"
import BASE_URL from "../../../baseUrl";

const bedstip = <Tooltip>Beds</Tooltip>;
const bathstip = <Tooltip>Bathrooms</Tooltip>;
const areatip = <Tooltip>Square Feet</Tooltip>;

const Content = ({ t }) => {
  const [advancesearch, setAdvanceSearch] = useState(false);
  const [filter, setFilter] = useState([]);
  const [state, setState] = useState([]);
  const [location, setLocation] = useState([]);
  const advancetoggle = () => {
    setAdvanceSearch(!advancesearch);
  };
  const Data = {
    locationlist: [
      t("Any Location"),
      
      t("Bangkok") ,
      t("Karachi Division"),
    ],
    statuslist: [
      t("For Sale Sold"),
      t("New Addition"),
      t("Featured Leased")
    ],
    type:[
      t("anyType"),
      t("Residential"),
      t("Condo"),
      t("Apartment"),
      t("Resort"),
      t("Land"),
      t("hotel")
  
    ],
    pricerangelist: [
      { res: t("Any Range"), value: "0" },
      { res: "$434", value: ["434"] },
      { res: "$34", value: ["34"] },
      { res: "$22", value: ["22"] },
    ],
    bedslist: [t("Any amount"), "34"],
    bathroomslist: [t("Any amount"), "34"],
    environmentlist: [
      t("Any Environment"),
      t("Beach"),
      t("Mountain"),
      t("Rural hills"),
      t("Urban"),
      t("Cities"),
    ],
    housearealist: [
      "Any House Area",
      "100-200",
      "200-300",
      "300-400",
      "400-500",
      "500+",
    ],
    landarealist: [
      "Any Land Area",
      "100-200",
      "200-300",
      "300-400",
      "400-500",
      "500+",
    ],
    floorlist: [t("Any floor"), "1st", "2nd", "3rd", "4th", "5th"],
    typelist: [t("Any floor"), "1st", "2nd", "3rd", "4th", "5th"],
    sortbylist: [
     t( "any"),
      t("Top Selling"),
      t("Most Popular"),
      t("High to low"),
      t("low to High"),
      t("Number of baths"),
      t("Number of beds"),
    ],
  };
  const {
    locationlist,
    statuslist,
    type,
    pricerangelist,
    bedslist,
    bathroomslist,
    floorlist,
    sortbylist,
  } = Data;

  const SubmitlistingData = async () => {
    const resposne = await fetch(
      `${BASE_URL}/submitlisting/submit`
    );
    const data = await resposne.json();
    const BuyFilter = data?.result?.filter((data)=>data?.listingType === "buy");
    if(BuyFilter){
      setState(BuyFilter);
    }
  };

  useEffect(() => {
    SubmitlistingData();
  }, []);


  const searchingFilter =(e)=>{
    e.preventDefault();
    
    axios.post(`${BASE_URL}/advanced-search`,{
      location:filter.location === "Any Location" ? "": filter.location,
      status:filter.status,
      price:filter.price ==="Any Range"?0:parseInt(filter.price),
      beds:filter.beds==="Any amount"?0:parseInt(filter.beds),
      bath:filter.bath === "Any amount"?0:parseInt(filter.bath),
      type:filter.type === "Any Type"?"":filter.type,
      floor:filter.floor === "Any floor"?0:parseInt(filter.floor)
    }).then((res)=>{
    setAdvanceSearch(!advancesearch);
     
      setState(res?.data?.result)
    }).catch((e)=>{console.error(e)})
  }

  const searchLocation = (e) => {
    e.preventDefault();

    axios.post(`${BASE_URL}/advanced-search`, {
        location: location,
    })
    .then((response) => {
      setState(response?.data?.result) // Logging the response data
    })
    .catch((error) => {
        console.error('Error occurred:', error); // Logging any errors that occur during the request
    });
}



    return (
      <div className="listing-map-wrapper">
        <div className="listing-main-wrapper">
          <div
            className={classNames("acr-filter-form", {
              "d-block": advancesearch,
            })}
          >
            <div className="acr-filter-form-header mt-5">
              <h4>{t("Filter")}</h4>
              <div
                className="close-btn close-dark filter-trigger mt-5"
                onClick={advancetoggle}
              >
                <span />
                <span />
              </div>
            </div>
            <form method="post" className="">
              <div className="row ">
                <div className="col-lg-4 col-md-6">
                  <div className="form-group acr-custom-select">
                    <div sx={{ m: 1, minWidth: 150 }}>
                 
                      
                    
                      <Select
                        defaultValue={t("location")}
                        style={{ width: 120 }}
                        onChange={(e)=>setFilter({...filter, location: e})}
                        options={locationlist?.map((res) => ({
                          value: res, // assuming res._id is the unique identifier for each location
                          label: res, // replace 'name' with the property of res that contains the name of the location
                        }))}
                      />

                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group acr-custom-select">
                    <div sx={{ m: 1, minWidth: 150 }}>
                     
                      
                     
                      
                      <Select
                        defaultValue={t("Status")}
                        style={{ width: 120 }}
                        onChange={(e)=>setFilter({...filter, status: e})}

                        options={statuslist?.map((res) => ({
                          value: res, // assuming res._id is the unique identifier for each location
                          label: res, // replace 'name' with the property of res that contains the name of the location
                        }))}
                      />

                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">

                  <div className="form-group acr-custom-select">
                   
                      <Select
                        defaultValue={t("Price Range")}
                        style={{ width: 120 }}
                        onChange={(e)=>setFilter({...filter, price: e})}
                        options={pricerangelist?.map((res) => ({
                          value: res?.value[0], // assuming res._id is the unique identifier for each location
                          label: res?.res, // replace 'name' with the property of res that contains the name of the location
                        }))}
                      />
                   
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="acr-custom-select form-group">
                  
                     <Select
                        defaultValue={t("beds")}
                        style={{ width: 120 }}
                        onChange={(e)=>setFilter({...filter, beds: e})}

                        options={bedslist?.map((res) => ({
                          value: res, // assuming res._id is the unique identifier for each location
                          label: res, // replace 'name' with the property of res that contains the name of the location
                        }))}
                      />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="acr-custom-select form-group">
                     <Select
                        defaultValue={t("Bathrooms")}
                        style={{ width: 120 }}
                        onChange={(e)=>setFilter({...filter, bath: e})}

                        options={bathroomslist?.map((res) => ({
                          value: res, // assuming res._id is the unique identifier for each location
                          label: res, // replace 'name' with the property of res that contains the name of the location
                        }))}
                      />
                  </div>
                </div>
                 <div className="col-lg-4 col-md-6">
                  <div className="acr-custom-select form-group">
                 
                     <Select
                        defaultValue={t("Type")}
                        style={{ width: 120 }}
                        onChange={(e)=>setFilter({...filter, type: e})}

                        options={type?.map((res) => ({
                          value: res, // assuming res._id is the unique identifier for each location
                          label: res, // replace 'name' with the property of res that contains the name of the location
                        }))}
                      />
                  </div>
                </div> 
                <div className="col-lg-4 col-md-6">
                  <div className="acr-custom-select form-group">
                   
                     <Select
                        defaultValue={t("Floor")}
                        style={{ width: 120 }}
                        onChange={(e)=>setFilter({...filter, floor: e})}

                        options={floorlist?.map((res) => ({
                          value: res, // assuming res._id is the unique identifier for each location
                          label: res, // replace 'name' with the property of res that contains the name of the location
                        }))}
                      />
                  </div>

                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="acr-custom-select form-group">
                  <Select
                  defaultValue={t("Sort by")}
                  style={{ width: 120 }}
                  options={sortbylist?.map((res) => ({
                    value: res, // assuming res._id is the unique identifier for each location
                    label: res, // replace 'name' with the property of res that contains the name of the location
                  }))}
                />
                  </div>
                </div> 
              </div>
              <div className="text-right">
                <button onClick={(e)=>searchingFilter(e)}   className="filter-trigger btn-custom">
                  {t("Apply filters")}
                </button>
              </div>
            </form>
          </div>
          <div className="acr-listing-filter-wrapper">
            <div className="row">
              <div className="col-lg-6 form-group">
                <div className="search-wrapper">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Search locations")}
                    onChange={(e)=>setLocation(e.target.value)}
                    name="search"
                  />
                  <button onClick={(e)=>searchLocation(e)}  className="btn-custom" name="button">
                    <i className="flaticon-search" />
                  </button>
                </div>
              </div>
              <div className="col-lg-6 form-group text-right">
                <button
                  type="button"
                  className="btn-custom-2 light-grey filter-trigger"
                  onClick={advancetoggle}
                >
                  {t("Advanced Search")}
                </button>
              </div>
            </div>
          </div>
          {/* Filter End */}

           <div className="row">
          {!state || state.length === 0 ? (
            <div className="">
              <div className="flex justify-center">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/img/Loading.gif`}
                  alt="Loading"
                />
              </div>
              <p className="text-center my-4 text-xl font-medium ">{t("No Data")}</p>
            </div>
          ) : (

            state?.map((res, key) => {
              const basicInformation = res?.BasicInformation;
              const deatils = res?.Details;
              const gallery = res?.Gallery;
              const author = res?.Author ? res?.Author : res?.Admin;
              return (
                <div  key={key}className="col-lg-6 col-md-6">
                  <div
                    className="listing listing-list myBestdealofMonth"
                    style={{ margin: "0.5rem" }}
                    key={key}
                  >
                    <div className="listing-thumbnail listingMainImage2" style={{ width: "80%" }}>
                      <Link
                        to={`/listing-details/${res._id}`}
                      >
                        <img
                          src={`${gallery?.file}`}
                          alt="listing"
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          className="listingMainImage2"
                        />
                      </Link>
                      <div className="listing-badges classBadge">

                        {basicInformation.status.split(", ").includes('For Sale') && <span className="listing-badge sale">{t("On Sale")}</span>}

                        <span className="listing-badge pending z"> {t("Pending")}</span>

                        {basicInformation.status.split(", ").includes('Rental') && <span className="listing-badge rent"> {t("Rental")}</span>}
                      </div>
                      <div className="listing-controls">
                          <Link to="#" className="favorite">
                            <i className="far fa-heart" />
                          </Link>
                      </div>
                    </div>
                    <div className="listing-body" style={{ width: "100%" }}>
                      <div className="listing-author">
                        <img
                          src={author?.pic || defaultpic}

                          alt="author"
                        />
                        <div className="listing-author-body">
                          <p>
                            {" "}
                            <Link to="#">{author?.name}</Link>{" "}
                          </p>
                          <span className="listing-date">
                            {res?.createdAt.split("T")[0]}
                          </span>
                        </div>
                        <Dropdown className="options-dropdown">
                          <Dropdown.Toggle as={NavLink}>
                            <i className="fas fa-ellipsis-v" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu-right">
                          <ul>
                          <li>
                            {" "}
                            <Link to="tel:+123456789">
                              {" "}
                              <i className="fas fa-phone" />  {t("callAgent")}
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link to="mailto:+123456789">
                              {" "}
                              <i className="fas fa-envelope" />  {t("sendMessage")}
                            </Link>{" "}
                          </li>
                          <li>
                            {" "}
                            <Link to="/listing-details">
                              {" "}
                              <i className="fas fa-bookmark" /> {t("Book Tour")}
                            </Link>{" "}
                          </li>
                        </ul>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <h5 className="listing-title">
                        {" "}
                        <Link
                          to={`/listing-details/${res?._id}`}
                          title={basicInformation?.name}
                        >
                          {basicInformation?.name}
                        </Link>{" "}
                      </h5>
                      <span className="listing-price">
                        {basicInformation?.price}
                        {`${basicInformation?.currency} `}
                        {basicInformation?.status.split(", ").includes('Rental') ? (
                          <span>/{basicInformation?.period.toLowerCase()}</span>
                        ) : (
                          <></>
                        )}
                      </span>
                      <p className="listing-text">
                    {basicInformation?.description.length > 70
                          ? basicInformation?.description.slice(0, 70) + "..."
                          : basicInformation?.description}
                  </p>

                      <div className="acr-listing-icons">
                        <OverlayTrigger overlay={bedstip}>
                          <div className="acr-listing-icon">
                            <i className="flaticon-bedroom" />
                            <span className="acr-listing-icon-value">
                              {deatils.beds}
                            </span>
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={bathstip}>
                          <div className="acr-listing-icon">
                            <i className="flaticon-bathroom" />
                            <span className="acr-listing-icon-value">
                              {deatils.bathrooms}
                            </span>
                          </div>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={areatip}>
                          <div className="acr-listing-icon">
                            <i className="flaticon-ruler" />
                            <span className="acr-listing-icon-value">
                              {basicInformation?.space} SQM
                            </span>
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div style={{ marginBottom: '10px' }}>
                        <span className="text-danger">{res.Location.country ? `${res.Location.city}, ${res.Location.country}` : "-----"}</span>
                      </div>
                      <div className="listing-gallery-wrapper">
                        <Link
                          to={`/listing-details/${res._id}`}
                          className="btn-custom btn-sm secondary"
                        >
                           {t("viewDetails")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        </div>
        <Listingmap />
      </div>
    );
  }


export default withTranslation()(Content);
