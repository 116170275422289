import React, { useState } from "react";
import FormInputGroup from "./FormInputGroup";
import { FaDollarSign, FaPercent } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Calculator = () => {
  const [homeValue, setHomeValue] = useState("");
  const [downPayment, setDownPayment] = useState("");
  const { t } = useTranslation();

  const [loanAmount, setLoanAmount] = useState("");
  const [interestRate, setInterestRate] = useState("");
  const [loanDuration, setLoanDuration] = useState("");
  const [monthlyPayment, setMonthlyPayment] = useState(0);

  const calculateLoanAmount = () => {
    setLoanAmount(homeValue - downPayment);
    return loanAmount;
  };

  const calculateMonthlyPayment = () => {
    const percentageToDecimal = (percent) => {
      return percent / 12 / 100;
    };

    const yearsToMonths = (year) => {
      return year * 12;
    };

    setMonthlyPayment(
      (percentageToDecimal(interestRate) * loanAmount) /
        (1 -
          Math.pow(
            1 + percentageToDecimal(interestRate),
            -yearsToMonths(loanDuration)
          ))
    );
    return monthlyPayment;
  };

  return (
    <div className="mortgage" style={{ margin: "1rem auto" }}>

      <form onSubmit={(e) => e.preventDefault()}>
        <FormInputGroup
          text={t("homeValue")}
          icon={<FaDollarSign />}
          placeholder={t("homeValue")}
          onkeyup={calculateLoanAmount}
          value={homeValue}
          onInput={(e) => setHomeValue(e.target.value)}
        />
        
        <FormInputGroup
          text={t("downPayment")}
          icon={<FaDollarSign />}
          placeholder={t("downPayment")}
          onkeyup={calculateLoanAmount}
          value={downPayment}
          onInput={(e) => setDownPayment(e.target.value)}
        />
        <FormInputGroup
          text={t("fundsNeeded")}
          icon={<FaDollarSign />}
          placeholder={t("fundsNeeded")}
          readOnly={true}
          value={loanAmount}
        />
        <FormInputGroup
          text={t("interestRate")}
          icon={<FaPercent />}
          placeholder={t("interestRate")}
          value={interestRate}
          onInput={(e) => setInterestRate(e.target.value)}
        />
        <FormInputGroup
          text={t("loanDuration")}

          placeholder={t("loanDuration")}
          value={loanDuration}
          onInput={(e) => setLoanDuration(e.target.value)}
        />
        <div className="alert alert-info font-sans text-lg font-bold">
         {t("Monthly payment")}: ${parseFloat(monthlyPayment.toFixed(2))}
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-lg w-100 center"
          onClick={calculateMonthlyPayment}
        >
         {t("Calculate")}
        </button>
      </form>
    </div>
  );
};

export default Calculator;
