import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../layouts/Headerfour'
import Breadcrumb from '../layouts/Breadcrumb'
import Footerthree from '../layouts/Footerthree'
import { useTranslation } from 'react-i18next'
import Content from "../sections/Getofferhome/content"

function GetHome() {
  const {t} = useTranslation();

  return (
    <div>
     <Fragment>
      <Helmet>
        <title>Buy Home For Less | Sell</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("Get offer for your home") }} />

      <Content />
      
      <Footerthree />
    </Fragment>
    </div>
  )
}

export default GetHome
