import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BsInfo = (props) => {
  const {  t } = useTranslation();

    let {data} = props
    
  return (
    <div className="section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-lg-30 acr-dots-wrapper acr-single-img-wrapper">
            <img
              src={process.env.PUBLIC_URL + data.picture}
              alt="img"
            />
            <div className="acr-dots" />
          </div>
          <div className="col-lg-6">
            <div className="section-title-wrap mr-lg-30">
              <h2 className="title">
                {data?.title}
              </h2>
              <p className="subtitle">
                {data?.para}
              </p>
              <ul className="acr-list">
              {data?.li1 ? <li>{data?.li1}</li> : null}

                {data?.li2 ? <li>{data?.li2}</li> :null }
                
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BsInfo;
