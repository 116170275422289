import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/services/Content";
import ManagementContent from "../sections/services/ManagmentContent";
import { useTranslation } from "react-i18next";

const Management = () => {
  const {t} = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Property Management</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("Property Management") }} />
      <ManagementContent />
      <Footer />
    </Fragment>
  );
};

export default Management;
