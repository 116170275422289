import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";
import CommentModal from "../../layouts/Modal";
const Testimonials = () => {
  const { t } = useTranslation();

  const [data, setData] = useState("");
  const [comment, setComment] = useState();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    axios.get(`${BASE_URL}/admin/pepleSay/get`).then((res) => {
      setData(res.data.data);
    });
  }, []);
  const openModal = (item) => {
    setComment(item);
    setOpen(true);
  };
  return (
    <div className="section section-padding testimonialpadding">
      <div className="container">
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">{t("testimonials")}</h5>
          <h2 className="title">{t("whatPeopleAreSaying")}</h2>
        </div>
        <div className="row">
          {Array.isArray(data) &&
            data.slice(0, 4).map((item, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-12">
                <div className="acr-testimonial">
                  <div className="acr-testimonial-author">
                    <img className="mb-4" src={item.img} alt="testimonial" />
                    <div className="acr-testimonial-author-inner ">
                      <h6>{item.userName}</h6>
                      <div>{item.companyname}</div>
                      <span>{item.destination}</span>
                      {item.comment.length > 30 ? (
                        <p className="text-sm">
                          {item.comment.slice(0, 10)}..
                          <span
                            style={{
                              cursor: "pointer",
                              color: "#0466c8",
                              fontWeight: "bold",
                            }}
                            onClick={() => openModal(item)}
                          >
                            {" "}
                            Read More
                          </span>
                        </p>
                      ) : (
                        <p className="text-sm">{item.comment}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
      {open && (
        <div>
          <CommentModal
            isOpen={open}
            closeModal={() => setComment(false)}
            selectedReview={comment}
          />
        </div>
      )}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
