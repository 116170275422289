import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";
import { toast } from "react-toastify";

const SellUs = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    tel: "",
    chatId: "",
    address: "",
    email: "",
    moo: "",
    tumbol: "",
    zipcode: "",
    city: "",
    country: "",
    propertyType: "residential",
    comment: "",
    amphorcity: "",
  });

  const [propertyImages, setPropertyImages] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setPropertyImages([...propertyImages, ...files]);

    const urls = files.map((file) => URL.createObjectURL(file));
    setImgUrls([...imgUrls, ...urls]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();

      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      propertyImages.forEach((image) => {
        formDataToSend.append("propertyImages", image);
      });

      const response = await axios.post(
        `${BASE_URL}/agent/send-sell-request`,
        formDataToSend
      );
      if (response) {
        toast({
          title: "success",
          description: "Sell request sent successfully",
          status: "success",
          duration: 3000,
          variant: "left-accent",
          position: "top-right",
          isClosable: true,
        });
      }
      // Clear the form fields
      setFormData({
        name: "",
        lastname: "",
        tel: "",
        chatId: "",
        email: "",
        address: "",
        moo: "",
        tumbol: "",
        zipcode: "",
        city: "",
        country: "",
        propertyType: "residential",
        comment: "",
        amphorcity: "",
      });

      // Clear the uploaded images
      setPropertyImages([]);
      setImgUrls([]);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container">
      <div className="my-16 py-10 rounded-2xl text-center border">
        <div>
          <h3 className="mb-8 text-themeColor">{t("sell_property")}</h3>
        </div>
        <div className="mx-8">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 form-group text-left">
                <label className="pl-3">{t("name")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("name")}
                  name="name"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 form-group text-left">
                <label className="pl-3">{t("Last Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Last Name")}
                  name="lastname"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, lastname: e.target.value })
                  }
                />
              </div>

              <div className="col-md-6 text-left form-group">
                <label className="pl-3">{t("Tel Number")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Tel Number")}
                  name="tel"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, tel: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 form-group text-left">
                <label className="pl-3">{t("WhatsApp/WeChat/LINE ID")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="ID"
                  name="chatId"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, chatId: e.target.value })
                  }
                />
              </div>

              <div className="col-md-6 form-group text-left">
                <label className="pl-3">{t("emailAddress")}</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder={t("emailAddress")}
                  name="email"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 text-left form-group">
                <label className="pl-3">{t("Address")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Address")}
                  name="address"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 form-group text-left">
                <label className="pl-3">{t("city_label")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("city_label")}
                  name="amphorcity"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, amphorcity: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 text-left form-group">
                <label className="pl-3">{t("Moo")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Moo")}
                  name="moo"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, moo: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 form-group text-left">
                <label className="pl-3">{t("Tumbol")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Tumbol")}
                  name="tumbol"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, tumbol: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 text-left form-group">
                <label className="pl-3">{t("zipcode_label")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("zipcode_label")}
                  name="zipcode"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, zipcode: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 text-left form-group">
                <label className="pl-3">{t("province_label")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("province_label")}
                  name="city"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                />
              </div>
              <div className="col-md-6 form-group text-left">
                <label className="pl-3">{t("Country")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("Country")}
                  name="country"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, country: e.target.value })
                  }
                />
              </div>

              <div className="col-md-6 text-left form-group">
                <label className="pl-3">{t("Type of Property")}</label>

                <select
                  className="form-control"
                  name="propertyType"
                  onChange={(e) =>
                    setFormData({ ...formData, propertyType: e.target.value })
                  }
                >
                  <option value="residential">{t("Residential")}</option>
                  <option value="commercial">{t("Commercial ")}</option>
                  <option value="land">{t("Land")}</option>
                </select>
              </div>

              <div className="col-md-12 text-left form-group">
                <label className="pl-3">{t("typeYourComment")}</label>
                <textarea
                  className="form-control"
                  rows="4"
                  placeholder={t("typeYourComment")}
                  name="comment"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, comment: e.target.value })
                  }
                ></textarea>
              </div>

              <div className="row">
                <div className="col-md-12 text-left form-group">
                  <p>{t("Upload Your Property Images")}</p>
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={ handleImageChange}
                    style={{ display: "none" }}
                  />
                  <label
                    className="custom-file-label cursor-pointer"
                  >
                    {t("Choose File")}
                  </label>
                </div>
              </div>

              {imgUrls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Property ${index + 1}`}
                  style={{ width: "200px", marginRight: "10px" }}
                />
              ))}

              <div className="text-left">
                <button type="submit" className="btn btn-primary">
                  {t("Submit")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SellUs;
