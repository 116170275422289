import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const Locationtab = ({ data, locationData }) => {
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [embedCode, setEmbedCode] = useState("");
  const [locationDetails, setLocationDetails] = useState({});
  const defaultPosition = [13.736717, 100.523186];
  const position = lat && long && !isNaN(lat) && !isNaN(long)
    ? [parseFloat(lat), parseFloat(long)]
    : defaultPosition;

  const customMarker = L.icon({
    iconUrl: process.env.PUBLIC_URL + "/assets/img/misc/marker.png",
    iconSize: [50, 50],
    iconAnchor: [25, 5],
  });

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleMarkerMove = (e) => {
    const { lat, lng } = e.target._latlng;
    setLat(parseFloat(lat.toFixed(6)));
    setLong(parseFloat(lng.toFixed(6)));
  };

  useEffect(() => {
    if (data?.address) {
      setAddress(data.address);
      fetchCoordinates(data.address);
    }
    if (data) {
      setEmbedCode(data.embedCode);
      setLocationDetails({
        city: data.city || "",
        tumbol: data.tumbol || "",
        provice: data.provice || "",
        zipcode: data.zipcode || "",
        country: data.country || "",
      });
    }
  }, [data]);

  useEffect(() => {
    if (address) {
      setLoading(true);
      fetchCoordinates(address);
      const timer = setTimeout(() => {
        if (lat && long) {
          fetchReverseGeocodingData(lat, long);
        }
      }, 2000); 
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [address, lat, long]);

  useEffect(() => {
    locationData({
      embedCode,
      address,
      ...locationDetails,
      lat,
      long,
    });
  }, [embedCode, address, locationDetails, lat, long]);

  const fetchCoordinates = async (address) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${address}&format=json&limit=1`
      );

      if (response.data && response.data.length > 0) {
        const { lat, lon, display_name } = response.data[0];
        setLat(lat);
        setLong(lon);
        setLocationDetails((prev) => ({ ...prev, displayName: display_name }));
      } else {
        setLocationDetails({});
      }
    } catch (error) {
      console.error("Error fetching location coordinates: ", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchReverseGeocodingData = async (lat, lon) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`
      );
      if (response.data) {
        const { address } = response.data;
        setLocationDetails({
          country: address.country || "",
          city: address.city || address.town || address.village || "",
          provice: address.state || address.region || "",
          zipcode: address.postcode || "",
          tumbol: address.suburb || "",
        });
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data: ", error);
    }
  };

  const handleLatChange = (e) => {
    const value = e.target.value;
    setLat(value === "" ? "" : parseFloat(value));
  };

  const handleLongChange = (e) => {
    const value = e.target.value;
    setLong(value === "" ? "" : parseFloat(value));
  };

  const handleEmbedCodeChange = (e) => {
    setEmbedCode(e.target.value);
  };

  return (
    <Fragment>
      <div className="form-group submit-listing-map">
        {embedCode ? (
          <iframe width="100%" height="95%" title="direction" src={embedCode}></iframe>
        ) : (
          <MapContainer zoom={13} center={position}>
            <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            <Marker position={position} icon={customMarker} draggable={true} eventHandlers={{ moveend: handleMarkerMove }}>
              <Popup position={position}>
                Current location: <pre>{JSON.stringify(locationDetails, null, 2)}</pre>
              </Popup>
            </Marker>
          </MapContainer>
        )}
      </div>
      <div className="form-group">
        <span className="acr-form-notice">
          Drag and drop the marker to identify your <b>Latitude</b> and <b>Longitude</b>
        </span>
      </div>
      <div className="row">
        <div className="col-md-12 form-group">
          <label>Full Address (Optional)</label>
          <input
            type="text"
            value={address}
            onChange={handleAddressChange}
            className="form-control"
            placeholder="Full Address (Optional)"
          />
          {loading && <p>Loading...</p>}
        </div>
      </div>
      <div className="row">
        {["Country", "Tumbol/kwang", "Amphor/city", "provice/State", "Zipcode"].map((label, index) => (
          <div className="col-lg-3 col-md-3 form-group" key={index}>
            <label>{label}</label>
            <input
              type="text"
              name={label}
              value={locationDetails[label.toLowerCase().replace(/\/|\s/g, "")] || ""}
              onChange={(e) => setLocationDetails((prev) => ({ ...prev, [label.toLowerCase().replace(/\/|\s/g, "")]: e.target.value }))}
              className="form-control"
              placeholder={label}
            />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-md-6 form-group">
          <label>Longitude (Optional)</label>
          <input
            type="text"
            value={long}
            onChange={handleLongChange}
            className="form-control"
            placeholder="Longitude"
          />
        </div>
        <div className="col-md-6 form-group">
          <label>Latitude (Optional)</label>
          <input
            type="text"
            value={lat}
            onChange={handleLatChange}
            className="form-control"
            placeholder="Latitude"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 form-group">
          <label>Embed Link</label>
          <input
            type="text"
            value={embedCode}
            onChange={handleEmbedCodeChange}
            className="form-control"
            placeholder="Embed Link"
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Locationtab;
