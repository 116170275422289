import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Content from "../sections/listingmap/Content";
import Headerfour from "../layouts/Headerfour";

class Listingmap extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Buy Home For Less | Listing Map</title>
          <meta name="description" content="#" />
        </Helmet>
        <Headerfour />
        <Content />
      </Fragment>
    );
  }
}

export default Listingmap;
