import React from 'react';

const ContactPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <h2 className='text-[20px]'>MSTAR (ASIA) Co., Ltd</h2>
          <div>
            <p><b>84/2 Moo 6</b><br />
            <b>Tungsonghong, Laksi</b><br />
            <b>Bangkok. 10210 Thailand</b><br />
            <b>Whatsapp: +66-973924632</b><br />
            <b>Line Id: Jackthai1</b><br />
            <b>Wechat: mstarabs</b></p>
          </div>
        <button className="close-button rounded-[10px]" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ContactPopup;
