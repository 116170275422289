import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Footer from "../layouts/Footer";
import Content from "../sections/listing-details/Content";

const Listingdetailsone = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [seo, setSeo1] = useState('')

  const setSeo = (e) => {
    if (e) {
      setSeo1(e)
    }
  }
  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Listing Details</title>
        <meta name={seo.title} content={seo.slug} />
      </Helmet>
      <Header />
      <Content setSeo={setSeo} />
      <Footer />
    </Fragment>
  );
};

export default Listingdetailsone;
