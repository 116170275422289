import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import ListingFilter from "../sections/pricing/ListingFilter";

const Breadcrumb = (props) => {
  const location = useLocation()
  const filterData = (data) => {
  }


  return (
    <div
      className={`subheader bg-cover bg-center dark-overlay ${location.pathname === "/buy" ? 'pricing-img' :
        location.pathname === "/about" ? "aboutuspricing-img" :
          location.pathname === "/services" ? "servicespricing-img" :
            location.pathname === "/sell" ? "sellpricing-img" :
              location.pathname === "/rent" ? "rentpricing-img" :
                location.pathname === "/Property" ? "propertypricing-img" :
                  location.pathname === "/Terms" ? "termspricing-img" :
                    location.pathname === "/Estate" ? "estatepricing-img" :
                      location.pathname === "/Retire" ? "retirepricing-img" :
                        location.pathname === "/submit-listing" ? "submitlistingpricing-img" :
                          location.pathname === "/property/Residential" ? "residetnalpricing-img" :
                            location.pathname === "/property/Hotel" ? "hotelpricing-img" :
                              location.pathname === "/property/Commercial" ? "commercialpricing-img" :
                                location.pathname === "/property/resort" ? "resort-img" :
                                  location.pathname === "/property/Land" ? "land-img" :
                                    location.pathname === "/property/Warehouse%20Factories" ? "warehouse-img" :
                                      ""}`}
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/img/subheader.jpg)`,
      }}
    >
      <div className="container">
        <div className="subheader-inner">
          <h1 className="text-white">{props.breadcrumb.pagename}</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  {" "}
                  <i className="fas fa-home" />{" "}
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {props.breadcrumb.pagename}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
