import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Header from '../../layouts/Headerfour';
import Breadcrumb from '../../layouts/Breadcrumb';
import Footerthree from '../../layouts/Footerthree';
import Content from '../../sections/Agent/Content';

function Agents() {
  const {t} = useTranslation();

  return (
    <div>
     <Fragment>
      <Helmet>
        <title>Buy Home For Less | Sell</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("agent") }} />
      <Content />
      
      <Footerthree />
    </Fragment>
    </div>
  )
}

export default Agents
