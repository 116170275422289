import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import Data from "../../../data/blog";
import classNames from "classnames";
import Loader from "../../layouts/Loader";
import axios from "axios";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";

function Content() {
  const [items, setItems] = useState(Data.blogblock);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(2);
  const [loading, setLoading] = useState(false);

  const {t} = useTranslation();


  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/blog/get-blogs`)
      .then((res) => {
        setItems(res.data)
      });
  }, []);

  const handleClick = (event) => {
    const paginationContent = event.target.closest(".pagination-content");

    if (paginationContent) {
      paginationContent.scrollIntoView();
    }

    setLoading(true);
    setTimeout(() => {
      setCurrentPage(Number(event.target.getAttribute("data-page")));
      setLoading(false);
    }, 2000);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const renderPagination = pageNumbers.map((number) => {
    const activeCondition = currentPage === number ? "active" : "";
    return (
      <Fragment key={number}>
        {pageNumbers.length > 1 ? (
          <li className={classNames("page-item", { active: activeCondition })}>
            <Link
              className="page-link"
              to="#"
              data-page={number}
              onClick={handleClick}
            >
              {number}
            </Link>
          </li>
        ) : (
          ""
        )}
      </Fragment>
    );
  });

  const indexOfLastitem = currentPage * itemsPerPage;
  const indexOfFirstitem = indexOfLastitem - itemsPerPage;
  const currentitems = items?.slice(indexOfFirstitem, indexOfLastitem);

  const renderitems = currentitems?.map((item, i) => {
    return (
      <article key={i} className="post">
        <div className="post-thumbnail">
          <Link to="#">
            <img
              src={item?.image}
              alt="blog post"
              width="100%"
            />
          </Link>
        </div>

        <div className="post-body">
          <div className="post-author">
            <img
              src={item?.image}
              alt="author"
            />
            <div className="post-author-body">
              <p>
              </p>
              <span className="post-date">{item?.postdate}</span>
            </div>
          </div>
          <h5 className="post-title">
            <Link>{item?.name}</Link>
          </h5>
          <p className="post-text">{item?.description}</p>
          <div className="post-controls">
          </div>
        </div>
      </article>
    );
  });

  return (
    <div className="section posts pagination-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            {loading === false ? renderitems : <Loader />}
            {pageNumbers.length > 1 && (
              <ul className="pagination">
                {pageNumbers.length > 1 && currentPage !== 1 && (
                  <li className="page-item">
                    <Link
                      className="page-link"
                      to="#"
                      data-page={currentPage - 1}
                      onClick={handleClick}
                    >
                      <i className="fas fa-chevron-left" />
                    </Link>
                  </li>
                )}
                {renderPagination}
                {pageNumbers.length > 1 && currentPage !== pageNumbers.length && (
                  <li className="page-item">
                    <Link
                      className="page-link"
                      to="#"
                      data-page={parseInt(currentPage + 1)}
                      onClick={handleClick}
                    >
                      <i className="fas fa-chevron-right" />
                    </Link>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Content;
