import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip, Dropdown, NavLink } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import defaultpic from "../../../assets/DefaultPic/profile.png";
import roomSize from "../../../assets/img/roomSize.png";
import CatListingFilter from "./CatListing-filter";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import ListingFilter from "../pricing/ListingFilter";
import Slider from "react-slick";

const gallerytip = <Tooltip>Gallery</Tooltip>;
const bedstip = <Tooltip>Beds</Tooltip>;
const bathstip = <Tooltip>Bathrooms</Tooltip>;
const areatip = <Tooltip>Square Feet</Tooltip>;

const Content = ({ listingtype, searchedData }) => {
  const [state, setState] = useState([]);
  const [NotFound, setNotFound] = useState(false);
  const [SearhingData, setSearhingData] = useState();
  const [Data, setdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const { t } = useTranslation();
  const { type } = useParams();
  const allType =
    listingtype === "Buy" || listingtype === "Sell"
      ? "Sell"
      : listingtype === "Rent"
        ? "Rent"
        : "all";
  const getData = async () => {
    try {
      if (searchedData?.msg === "location data") {
        setState(searchedData?.data);
      } else {
        const response = await fetch(
          `${BASE_URL}/submitlisting/submitListingType/${type}/${allType}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              mode: "no-cors",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data from the server");
        }
        const data = await response.json();

        if (data.result?.length === 0) {
          setNotFound(true);
        } else {
          setNotFound(false);
        }
        setState(data.result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, [listingtype, searchedData?.msg, type]);

  useEffect(() => {
    if (SearhingData) {
      setdata(SearhingData);
    }
  }, [SearhingData]);

  const getSearchData = async () => {
    try {
      if (searchedData?.msg === "location data") {
        setState(searchedData?.data);
      } else {
        const response = await axios.post(`${BASE_URL}/submitlisting/search`, {
          city: Data.city,
          type: Data.Propertytype ? Data.Propertytype : "Any Property",
          priceRange: Data.Pricerange,
          country: Data.country,
        });
        if (response) {
          setState(response.data.result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const acessChat = () => {
    return;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };

  const filterData = (data) => { };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = state.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(state.length / itemsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="container flex justify-center">
        <div className="shadow-sm mt-3 rounded">
          <ListingFilter getData={filterData} />
        </div>
      </div>
      <div className="flex justify-center">
        <div className="w-9/12 my-8 container">
          {NotFound ? (
            <div className="flex justify-center">
              <div className="md:w-9/12 lg:w-4/6 my-4 border p-10 border-gray-500 rounded-lg flex justify-between items-center">
                <div className="w-32 pr-12">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/img/exclaimationMark.png`}
                    alt="ExclaimationMark"
                    width={"100%"}
                  />
                </div>
                <div className="w-10/12">
                  <h2 className="mb-4 text-gray italic">
                    {t(`${type} Property Not Found`)}
                  </h2>
                  <p className="text-gray-200">{t(`Sorry, the `)}</p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="row">
                {currentItems.length > 0 ? (
                  currentItems.map((res, key) => {
                    const basicInformation = res.BasicInformation;
                    const details = res.Details;
                    const author = res?.Author ? res?.Author : res?.Admin;
                    const Gallery = res.Gallery;
                    return (
                      <div key={key} className="col-lg-4 col-md-6">
                        <div className="listing">
                          <div className="listing-thumbnail">
                            <Link to={`/listing-details/${res._id}`}>
                              <div
                                style={{
                                  width: "100%",
                                  height: "220px",
                                  overflow: "hidden",
                                }}
                              >
                                <Slider {...settings}>
                                  {Gallery?.file?.map((file, index) => (
                                    <div key={index}>
                                      <img
                                        src={file}
                                        alt={`listing-${index}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    </div>
                                  ))}
                                </Slider>
                              </div>
                            </Link>
                            <div className="listing-badges">
                              <span className="listing-badge featured">
                                {" "}
                                <i className="fas fa-star" />{" "}
                              </span>
                              {basicInformation.status
                                .split(", ")
                                .includes("For Sale") && (
                                  <span className="listing-badge sale">
                                    On Sale
                                  </span>
                                )}
                              {basicInformation.listingType
                                .split(", ")
                                .includes("Rent") && (
                                  <span className="listing-badge rent">
                                    {" "}
                                    Rental
                                  </span>
                                )}
                            </div>
                          </div>
                          <div className="listing-body">
                            <div className="listing-author">
                              <img
                                src={author?.pic || defaultpic}
                                alt="author"
                              />
                              <div className="listing-author-body">
                                <p>
                                  {" "}
                                  <Link to="#">{author?.name}</Link>{" "}
                                </p>
                                <span className="listing-date">
                                  {res.createdAt.split("T")[0]}
                                </span>
                              </div>
                            </div>
                            <h5 className="listing-title">
                              {" "}
                              <Link
                                to={`/listing-details/${res._id}`}
                                title={basicInformation.name}
                              >
                                {basicInformation.name}
                              </Link>{" "}
                            </h5>
                            
                            <p className="listing-text">
                              {basicInformation.description
                                ? basicInformation.description.slice(0, 50) +
                                "...."
                                : ""}
                            </p>
                            <div className="acr-listing-icons mb-2">
                              <OverlayTrigger overlay={bedstip}>
                                <div className="acr-listing-icon">
                                  <i className="flaticon-bedroom" />
                                  <span className="acr-listing-icon-value">
                                    {details.beds}
                                  </span>
                                </div>
                              </OverlayTrigger>
                              <OverlayTrigger overlay={bathstip}>
                                <div className="acr-listing-icon">
                                  <i className="flaticon-bathroom" />
                                  <span className="acr-listing-icon-value">
                                    {details.bathrooms}
                                  </span>
                                </div>
                              </OverlayTrigger>
                              <OverlayTrigger overlay={areatip}>
                                {details?.lotsize === "undefined" ? (
                                  <></>
                                ) : (
                                  <div className="acr-listing-icon">
                                    <img width="20px" src={roomSize} />
                                    <span className="mx-2 acr-listing-icon-value">
                                      {details.lotsize}
                                    </span>
                                  </div>
                                )}
                              </OverlayTrigger>
                            </div>
                            <div>
                              <span className="listing-price text-end" style={{fontSize:"25px"}}>
                              {
                              basicInformation?.originalprice && (
                                <span className="listing-price-left text-[10px]">
                                  {(listingtype == "Buy" || listingtype == "Sell")?"Original Price":"Original Rental Price: "}
                                  {
                                    basicInformation.originalprice 
                                    ? Number(basicInformation.originalprice).toLocaleString() 
                                    : ''                                  
                                  }
                                </span>
                              )
                            }
                                {basicInformation.listingType === "Rent" ? (
                                  <>
                                    {basicInformation.currency}
                                    {Number(basicInformation?.rentalPrice).toLocaleString()}

                                  </>
                                ) : (
                                  <>
                                    {basicInformation.currency}
                                    {Number(basicInformation?.price).toLocaleString()}

                                  </>
                                )}
                                {basicInformation?.listingType == "Rent" && (
                                  <>
                                    <span>/{basicInformation?.period}</span>
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="listing-gallery-wrapper">
                            <Link
                              to={`/listing-details/${res._id}`}
                              className="btn-custom btn-sm secondary mr-2"
                            >
                              {t("View Details")}
                            </Link>
                            <div className="acr-listing-icon">
                              {/* <p className=" acr-listing-icon-value me-2">
                                {(res?.Location?.city !== "undefined" &&
                                  res?.Location?.city) && (
                                    <>
                                      {t('City/Amphor')}:{res?.Location?.city}
                                    </>
                                  )}

                              </p> */}
                              <p>
                                {(res?.Location?.provice !== "undefined" &&
                                  res?.Location?.provice) && (
                                    <>
                                      Province {" "}:{res?.Location?.provice}
                                    </>
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex justify-center mt-5">
                    <h5>{t("No results found")}</h5>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-center mt-4">
                <nav aria-label="...">
                  <ul className="pagination">
                    <li
                      className={`page-item ${currentPage === 1 ? "disabled" : ""
                        }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage - 1)}
                      >
                        Previous
                      </button>
                    </li>
                    {[...Array(totalPages)].map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${currentPage === index + 1 ? "active" : ""
                          }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${currentPage === totalPages ? "disabled" : ""
                        }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Content;
