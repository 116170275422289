import React from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footerthree from "../layouts/Footerthree";
import Content from "../sections/rent/Content";
import { useTranslation } from "react-i18next";
import Footer from "../layouts/Footerthree";
import { Fragment } from "react";
import RentalguidelineContent from "./RentalguidelineContent";
function Rentalguideline() {
  const { t } = useTranslation();
  return (
    <Fragment>
    <Helmet>
      <title>Buy Home For Less | Pricing</title>
      <meta name="description" content="#" />
    </Helmet>
    <Header />
    <Breadcrumb breadcrumb={{ pagename: t("Rent") }} />
    <RentalguidelineContent/>
    <Footer />
  </Fragment>
  );
}

export default Rentalguideline;
