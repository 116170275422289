import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Heading,
  VStack,
  useToast,
  Input,
  Select,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import BASE_URL from "../../baseUrl";
function SellContent() {
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    telephone: "",
    line:"",
    typeofProperty: "",
    comment: "",
  });

  const toast = useToast();

  const handleChange = (name,value) => {
    // const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle phone input changes based on the field name (tel, whatsapp, wechat, line)
  const handlePhoneChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value, // Dynamically update the phone fields
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior immediately
    console.log(formData);

    try {
      const response = await axios.post(`${BASE_URL}/sellPropertyToUs`, formData);

      // Check the response status properly (axios returns status codes, not `ok`)
      if (response.status === 200 || response.status === 201) {
        toast({
          title: "Form submitted successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        // Clear form fields after successful submission
        setFormData({
          name: "",
          lastname: "",
          telephone: "",
          line: "",
          typeofProperty: "",
          comment: "",
        });
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      toast({
        title: "Error submitting form",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="section sm:mt-[100px] mb-5">
      <Box
        maxW="600px"
        mx="auto"
        mt="50px"
        p="8"
        boxShadow="lg"
        rounded="md"
        bg="white"
      >
        <Heading as="h1" size="lg" textAlign="center" mb="6">
          Sell Property To Us
        </Heading>

        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={formData.name}
                onChange={(e)=>handleChange('name',e.target.value)}
                placeholder="Enter your name"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Lastname</FormLabel>
              <Input
                name="lastname"
                value={formData.lastname}
                onChange={(e)=>handleChange('lastname',e.target.value)}
                placeholder="Enter your lastname"
              />
            </FormControl>

            {/* Telephone Field */}
            <FormControl isRequired>
              <FormLabel>Telephone</FormLabel>
              <PhoneInput
                country={"th"}
                value={formData.telephone}
                onChange={(value) => handlePhoneChange(value, "telephone")}
                inputStyle={{ width: "100%" }}
                placeholder="Enter your phone number"
                name="tel"
              />
            </FormControl>

            {/* Line Field */}
            <FormControl>
              <FormLabel>Line</FormLabel>
              <PhoneInput
                country={"th"}
                value={formData.line}
                onChange={(value) => handlePhoneChange(value, "line")}
                inputStyle={{ width: "100%" }}
                placeholder="Enter your Line ID"
                name="line"
              />
            </FormControl>
            <FormControl id="propertyType" mb="4">
              <FormLabel>Type of Property</FormLabel>
              <Select
                name="typeofProperty"
                value={formData.typeofProperty}
                onChange={(e)=>handleChange('typeofProperty',e.target.value)}
                placeholder="Select property type"
                required
              >
                <option value="villa">Villa/Residential</option>
                <option value="condo">Condo</option>
                <option value="apartment">Apartment</option>
                <option value="resort">Resort</option>
                <option value="hotel">Hotel</option>
                <option value="commercial">Commercial Building</option>
                <option value="land">Land</option>
              </Select>
            </FormControl>
            {/* Comment Field */}
            <FormControl>
              <FormLabel>Comment</FormLabel>
              <Textarea
                name="comment"
                value={formData.comment}
                onChange={(e)=>handleChange('comment',e.target.value)}
                placeholder="Enter your comments"
              />
            </FormControl>

            <Button colorScheme="teal" type="submit" width="full">
              Submit
            </Button>
          </VStack>
        </form>
      </Box>
    </div>
  );
}

export default SellContent;
