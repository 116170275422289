import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footer";
import Content from "../sections/services/Content";
import PlannerContent from "../sections/services/PlannerContent";
import { useTranslation } from "react-i18next";

const Planner = () => {
  const {t} = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Investment Planner</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("Investment Planner" )}} />
      <PlannerContent />
      <Footer />
    </Fragment>
  );
};

export default Planner;
