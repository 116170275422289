const Data = {
  locationlist: [
    "Any Location",
    
    "Bangkok",
    "Karachi Division",
  ],
  statuslist: [
    "For Sale, Sold",
    "New Addition",
    "Featured, Leased"
  ],
  type:[
    "Any Type",
    "Residential",
    "Condo",
    "Apartment",
    "Resort",
    "Land",
    "Hotel"

  ],
  pricerangelist: [
    { res: "Any Range", value: "0" },
    { res: "$434", value: ["434"] },
    { res: "$34", value: ["34"] },
    { res: "$22", value: ["22"] },
  ],
  bedslist: ["Any amount", "34"],
  bathroomslist: ["Any amount", "34"],
  environmentlist: [
    "Any Environment",
    "Beach",
    "Mountain",
    "Rural hills",
    "Urban",
    "Cities",
  ],
  housearealist: [
    "Any House Area",
    "100-200",
    "200-300",
    "300-400",
    "400-500",
    "500+",
  ],
  landarealist: [
    "Any Land Area",
    "100-200",
    "200-300",
    "300-400",
    "400-500",
    "500+",
  ],
  floorlist: ["Any floor", "1st", "2nd", "3rd", "4th", "5th"],
  typelist: ["Any floor", "1st", "2nd", "3rd", "4th", "5th"],
  sortbylist: [
    "Any",
    "Top Selling",
    "Most Popular",
    "Price: High - Low",
    "Price: Low - High",
    "Number of baths",
    "Number of beds",
  ],
};

export default Data;
