import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/ThinkToKnow/TermsContent";
import Download from "../layouts/App";
import BsInfo from "../sections/services/Bsinfo";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const {t} = useTranslation();

  let data = {
    title:t("Get Long Term Visa in Thailand"),
    para:t("We can help you get"),
    picture:"/assets/img/listings-list/TermVis.png"
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          Buy Home For Less | Get a long terms visa in Thailand
        </title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb
        breadcrumb={{ pagename: t("Get Long Term Visa in Thailand") }}
      />

    <BsInfo data={data} />
      <Download />
      <Footer />
    </Fragment>
  );
};

export default Terms;
