import React from "react";
import { useTranslation } from "react-i18next";

const TermsContent = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="my-16  py-10 rounded-2xl text-center border">
        <div>
          <h3 className="mb-8 text-themeColor">{t("terms_condition")}</h3>
        </div>
        <div
          className="my-20 text-medium font-medium text-start"
          style={{ paddingLeft: "30px", paddingRight: "30px" }}
        >
          <p className="mb-2 font-bold text-[20px]">{t("General")}</p>
          <p className="font-bold">{t("Buyhomeforless.com")}</p>
          <p className="font-light ms-1">{t("Buyhomeforless.compara")}</p>
          <p className="font-bold">{t("Acceptance of Terms")}</p>
          <p className="font-light">{t("Acceptance of Termspara")}</p>
          <p className="font-bold">{t("General Use of the Mstar")}</p>
          <p className="font-light">{t("General Use of the Mstar Para")}</p>
          <p className="font-bold">
            {t("Content Contained in the Move Network")}
          </p>
          <p className="font-light">
            {t("Content Contained in the Move Network para")}
          </p>
          <p className="font-bold">{t("Content You Provide")}</p>
          <p className="font-light">{t("Content You Provide para")}</p>
          <p className="font-bold">{t("Mortgage-Related Disclosure")}</p>
          <p className="font-light">
            {t("Mortagepara")}
          </p>
          <p className="font-bold">
            {t("Ability to Opt Out from Receiving Certain Communications")}
          </p>
          <p className="font-light">{t("Abilitypara")}</p>
          <p className="font-bold">{t("GeneralTerms")}</p>
          <p className="font-bold">{t("ApplicableLaw;AgreementtoArbitrate")}</p>
          <p className="font-light">{t("GeneralTermspara")}</p>
          <p className="font-bold">{t("InterpretationoftheTermsofUse")}</p>
          <p className="font-light">{t("InterpretationoftheTermsofUsepara")}</p>
          <p className="font-bold">{t("DisclaimerofWarranties")}</p>
          <p className="font-light">{t("DisclaimerofWarrantiespara")}</p>
          <p className="font-bold">{t("Indemnification")}</p>
          <p className="font-light">{t("Indemnificationpara")}</p>
          <p className="font-bold">{t("LimitationofLiability")}</p>
          <p className="font-light">{t("limitationpara")}</p>

          <p className="font-bold">{t("Copyright")}</p>
          <p className="font-light">{t("Copyrightpara")}</p>
          <p className="font-bold mb-1">{t("Trademarks")}</p>
          <p className="font-light">{t("Trademarkspara")}</p>
        </div>
      </div>
    </div>
  );
};

export default TermsContent;
