import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Info = () => {
  const {  t } = useTranslation();

  return (
    <div className="py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-lg-30 acr-dots-wrapper acr-single-img-wrapper">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/listings-list/4.jpg"}
              alt="img"
            />
            <div className="acr-dots" />
          </div>
          <div className="col-lg-6">
            <div className="section-title-wrap mr-lg-30">
              <h2 className="title">
              {t('heading')}
              </h2>
              <p className="subtitle">
              <p>{t('greeting')}</p>  
                
              </p>
              <ul className="acr-list">
                <li>{t('li')}</li>
                <li>{t('li2')}</li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
