import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyContent = () => {
  const {t}=useTranslation();
  return (
   <div className="container">
     <div className="my-16  py-10 rounded-2xl text-center border">
      <div>
        <h3 className="mb-8 text-themeColor">
       {t("privacy_policy")}
        </h3>
      </div>
      <div
        className="my-20 text-medium font-medium text-start"
        style={{ paddingLeft: "30px", paddingRight: "30px" }}
      >
        <p className="font-light">
          {t('privacy_policy_para')}
        </p>
        <p className="font-bold">
          {t('Information We Collect; Tracking Policies')}
        </p>
        <p className="font-light">
          {t('Information We Collect; Tracking Policies para:')}
        </p>
        <p className="font-bold">
          {t('Our Use of Your Personal Data and Other Information:')}
        </p>
        <p className="font-light">
          {t('Our Use of Your Personal Data and Other Information para:')}
        </p>
        <p className="font-bold">
          {t('HOW AND WHEN YOUR INFORMATION IS SHARED WITH OTHER PARTIES: ')}
        </p>
        <p className="font-light">
          {t('HOW AND WHEN YOUR INFORMATION IS SHARED WITH OTHER PARTIES para:')}
        </p>
        <p className="font-bold">
          {t('Exclusions')}
        </p>
        <p className="font-light">
          {t('Exclusions para')}
        </p>
        <p className="font-bold">
          {t('Ownership and Purpose of the Site and Services: ')}
        </p>
        <p className="font-light">
          {t('Ownership and Purpose of the Site and Services para')}
        </p>
        <p className="font-bold">
          {t('Children and Minors: ')}
        </p>
        <p className="font-light">
          {t('Children and Minors para')}
        </p>
        <p className="font-bold">
          {t('Links to Other Web Sites: ')}
        </p>
        <p className="font-light">
          {t('Links to Other Web Sites para:')}
        </p>
        <p className="font-bold">
          {t('Security')}
        </p>
        <p className="font-light">
          {t('Security para')}
        </p>
        <p className="font-bold">
          {t('Special Notice to Thailand Residents:')}
        </p>
        <p className="font-light">
          {t('Special Notice to Thailand Residents para:')}
        </p>
        <p className="font-bold">
          {t('Compliance with Thailand Federal Laws: ')}
        </p>
        <p className="font-light">
          {t('Compliance with Thailand Federal Laws:  para')}
        </p>
        <p className="font-bold">
          {t('Other Terms and Conditions:')}
        </p>
        <p className="font-light">
          {t('Other Terms and Conditions para:')}
        </p>
        <p className="font-bold">
          {t('Changes to Privacy Policy: ')}
        </p>
        <p className="font-light">
          {t('Changes to Privacy Policy para:')}
        </p>
        <p className="font-bold">
          {t('Access to Information; Contacting Us: ')}
        </p>
        <p className="font-light">
          {t('Access to Information; Contacting Us para:')}
        </p>
      </div>
    </div>
   </div>
  );
};

export default PrivacyContent;
