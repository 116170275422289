import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Menu } from "antd";
import BASE_URL from "../../baseUrl";

const Mobilemenu = () => {
  const navig = useNavigate();
  const [mainLists, setMainLists] = useState([]);
  const [statuslist, setStatuslist] = useState([]);
  const [openKeys, setOpenKeys] = useState(['sub1']);

  const fetchMainLists = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/NavList/api/mainlists`);
      const data = response?.data.sort((a, b) => a.index - b.index);
      setMainLists(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchStatusList = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/admin/get-categories`);
      setStatuslist(res.data.result);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchMainLists();
    fetchStatusList();
  }, []); // Ensure the effect runs only once on mount

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const rootSubmenuKeys = ['sub1'];

  const mainPages = {
    "Home": "home",
    "About Us": "about",
    "Services": "services",
    "Rent": "rent",
    "sell": "sell",
    "Buy": "buy",
    "Thing To Know ": "category"
  };

  const subPages = {
    "Help With Distress Home": "Distress",
    "Financial Planing": "Planing",
    "Loan Modification": "Modification",
    "Property Management": "Management",
    "Investment Planner": "Planner",

    "How to buy poperty in Thailand": "Property",
    "Get Long Term Visa in Thailand": "Terms",
    "Thailand Real estate Laws": "Estate",
    "How to retire in Thailand": "Retire",

    "Residential": "property/Residential",
    "Hotel": "property/Hotel",
    "Condo": "property/Condo",
    "Commercial": "property/Commercial",
    "Apartment": "property/Apartments",
    "Land": "property/Land",
    "Resort": "property/Resort",
    "Warehouse & Factories": "property/Warehouse%20&%20Factories",
    "Retails": "property/Retails"
  };

  return (
    <div className="aside-scroll">
      <div>
        {mainLists && mainLists.length > 0 ? (
          <Menu
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
            style={{ width: 256 }}
          >
            {mainLists.map((mainListItem, index) => (
              mainListItem.subLists.length === 0 ? (
                <Menu.Item key={index}>
                  <Link to={`/${mainPages[mainListItem.title]}`}>{mainListItem.title}</Link>
                </Menu.Item>
              ) : (
                <Menu.SubMenu
                  key={mainListItem._id}
                  title={mainListItem.title}
                >
                  {mainListItem.subLists.map((subListItem) => (
                    <Menu.Item key={subListItem._id}>
                      <Link to={`/${subPages[subListItem.title]}/${mainListItem.title}`} style={{borderBottom:"2px solid black"}}>{subListItem.title}</Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              )
            ))}
          </Menu>
          // <></>
        ) : null}
      </div>
    </div>
  );
};

export default Mobilemenu;
