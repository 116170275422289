import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "magnific-popup";
import { useTranslation } from "react-i18next";
import ContactPopup from "./ContactPopup";

const Gallery = () => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="py-5">
      <div className="container">
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">{t("OurOffice")}</h5>
          <h5 className="title">{t("WorkingWithTopReal")}</h5>
        </div>
        <div className="row gallery">
          <div className="col-lg-6">
            <Link to="" className="gallery-thumb">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/building.png"}
                alt="services"
                className="w-100"
              />
            </Link>
          </div>
          <div className="col-lg-6">
            <Link to="" className="gallery-thumb">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/services/5.jpg"}
                alt="services"
              />
            </Link>
            <Link to="" className="gallery-thumb">
              <img
                src={process.env.PUBLIC_URL + "/assets/img/services/6.jpg"}
                alt="services"
              />
            </Link>
            <div className="justify-start min-h-[50px]" style={{display:"flex"}}> 
              <button onClick={handleOpenPopup} className="button_contact_page bg-transparent border-2 rounded-[10px] px-4 py-2">
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
      <ContactPopup isOpen={isPopupOpen} onClose={handleClosePopup} />
    </div>
  );
};

export default Gallery;
