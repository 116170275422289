import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/bloglist/Content";

class Bloglist extends Component {
  render() {
    const { t } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>Buy Home For Less | Blog List</title>
          <meta name="description" content="#" />
        </Helmet>
        <Header />
        <Breadcrumb breadcrumb={{ pagename: t("Blog List") }} />
        <Content />
        <Footer />
      </Fragment>
    );
  }
}

export default withTranslation()(Bloglist);
