import React from "react";
import Footerthree from "../layouts/Footerthree";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import { useTranslation } from "react-i18next";
import BsInfo from "../sections/services/Bsinfo";
import Breadcrumb from "../layouts/Breadcrumb";

function ThailandLaws() {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less |Own Property in Thailand</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("Own Property in Thailand") }} />
      <div className="container">
        <div className="my-3 text-center mb-3">
          <h4>{t("Thai real estate laws")}</h4>
          <p>{t("FOR FOREIGNERS")}</p>
        </div>
        <div className="lawspara mb-3">
          <p>{t("firstpara")}</p>
        </div>
        <div className="lawlist mb-3">
          <ul>
            <li>
              <span style={{ color: "blue" }}>
                {t("Civil code book III Specific Contracts")} {""}
              </span>
              {t("containing laws governing property leases and mortgage")}
            </li>
            <li>
              <span style={{ color: "blue" }}>
                {t("Civil code book IV 'Property")}
              </span>{" "}
              {""}
              {t(
                "containing laws governing ownership, possession, usufruct, superficies"
              )}
            </li>
            <li>
              <a
                href="https://www.thailandlawonline.com/civil-and-commercial-code/453-457-book-iii-specific-thai-contracts-law"
                target="blank"
                style={{ color: "blue" }}
              >
                {t("The Thailand Condominium Act")} {""}
              </a>
            </li>
            <li>
              <a
                href="https://www.thailandlawonline.com/civil-and-commercial-code/453-457-book-iii-specific-thai-contracts-law"
                target="blank"
                style={{ color: "blue" }}
              >
                {t("The Thailand Land Code Act")} {""}
              </a>
            </li>
            <div className="ms-5">
              <p className="">{t("thailandpara1")}</p>
              <h6 className="font-bold">{t('Foreign land ownership in Thailand')}</h6>
              <p>{t("foreignpara")}</p>
              <h6 className="font-bold">{t('Inheritance of land (by foreigners)')}</h6>
              <p>{t("Inheritance of land")}</p>
              <h6 className="font-bold">{t('condoAppartment')}</h6>
              <p>{t("condoAppartmentpara")}</p>
              <h6 className="font-bold">{t('Lease and tenancy by foreigners')}</h6>
              <p>{t("leasepara")}</p>
              <h6 className="font-bold">{t('Foreign house ownership separate from the land ')}</h6>
              <p>{t("Foreign house ownership separate from the land para")}</p>
              <h6 className="font-bold">{t('Thai house book')}</h6>
              <p>{t("Thai house book para")}</p>
              <h6 className="font-bold">{t('thaimarried')}</h6>
              <p>{t("thaimarriedpara")}</p>
              <h6 className="font-bold">{t('thailandright')}</h6>
              <p>{t("thailandrightpara")}</p>
              <h6 className="font-bold">{t('Thailand right of habitation')}</h6>
              <p>{t('Thailand right of habitation para')}</p>
              <h6 className="font-bold">{t('Thailand right of servitude')}</h6>
              <p>{t("Thailand right of servitude para")}</p>
              <h6 className="font-bold">{t('Partly foreign owned Thai land holding company')}</h6>
              <p>{t('Partly foreign owned Thai land holding company para')}</p>
              <h6 className="font-bold">{t('Thailand land titles and deeds')}</h6>
              <p>{t('Thailand land titles and deeds para')}</p>
              <h6 className="font-bold">{t('Transfer fees')}</h6>
              <p>{t('Transfer fees para')}</p>
              <ul className=" mb-3">
                <li className="font-bold">{t('Transfer Fee of 2%')}</li>
                <li className="font-bold">{t('Specific Business Tax of 3.3% if applicable')}</li>
                <li className="font-bold">{t('Stamp Duty of 0,5% does not have to be paid when Specific Business Tax is charged')}</li>
                <li className="font-bold"> {t('Withholding (Income) Tax calculated at a progressive rate for individuals and is fixed at 1% for corporations.')}</li>
              </ul>
              <p>{t('transferfeelisting')}</p>


            </div>
          </ul>
        </div>
      </div>
      <Footerthree />
    </Fragment>
  );
}

export default ThailandLaws;
