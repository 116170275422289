import React from "react";
import { Button } from "antd";
import { RiCloseLine } from "react-icons/ri"; // Close icon

const CommentModal = ({
  isOpen,
  closeModal,
  selectedReview,
  backgroundColor,
  textColor,
}) => {
  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: backgroundColor ? backgroundColor : "white",
    borderRadius: "20px",
    padding: "20px",
    maxWidth: "80vw", // Adjusted for responsiveness
    width: "600px", // Fixed width or adjust as needed
    maxHeight: "80vh", // Adjusted for responsiveness
    overflowY: "auto", // Enable scrolling if content exceeds maxHeight
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
    zIndex: 999,
    display: isOpen ? "block" : "none", // Toggle visibility based on isOpen
  };

  return (
    <>
      {selectedReview && isOpen && (
        <div style={modalStyle} className="reviewModal">
          <button
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "none",
              border: "none",
              cursor: "pointer",
              color: "#999",
              fontSize: "24px",
            }}
            onClick={closeModal}
          >
            <RiCloseLine />
          </button>
          {selectedReview && (
            <div style={{ textAlign: "center" }}>
              <img
                src={selectedReview.img}
                alt="No Image"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  marginBottom: "10px",
                  display: "block",
                  margin: "0 auto", // Centering the image horizontally
                }}
              />
              <h4 style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
                {selectedReview.userName}
              </h4>
              <p style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
                {selectedReview.companyname}
              </p>
              <p style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
                {selectedReview.destination}
              </p>
              <p style={{ fontFamily: "Poppins, sans-serif", color: textColor }}>
                {selectedReview.comment}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CommentModal;
