import React, { Fragment } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import Footerthree from "../components/layouts/Footerthree";
import Headerfour from "../components/layouts/Headerfour";
import ContentAgent from "./ContentAgent";

const AddAgent = () => {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | AddAgent</title>
        <meta name="description" content="#" />
      </Helmet>
      <Headerfour/>
      <Breadcrumb breadcrumb={{ pagename: t("Become our agent") }} />
      <ContentAgent/>
      <Footerthree/>
    </Fragment>
  );
};

export default AddAgent;