import React, { useState, useEffect, Fragment } from "react";
import Menu from "../layouts/Menu";
import Mobilemenu from "../layouts/Mobilemenu";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { Dropdown, NavLink } from "react-bootstrap";
import { useLanguage } from "../../Context/LanguageContext";
import BASE_URL from "../../baseUrl";
import axios from "axios";

const Headerfour = () => {
  const [navtoggle, setnavtoggle] = useState(false);
  const [userActive, setuserActive] = useState();
  const [sticky, setSticky] = useState("false");
  const [url, setUrl] = useState("");
  const { handleLanguageChange } = useLanguage();
  const [langSet, setLangSet] = useState();
  const [Links, setLinks] = useState("");
  const { i18n, t } = useTranslation();

  const user = JSON.parse(localStorage.getItem("userInfo"));

  const logout = () => {
    localStorage.removeItem("userInfo");
    window.location.replace("/");
  };


  let a = JSON.parse(localStorage.getItem("userInfo"));
  var sUrl;
  const CurrentUrl = window.location.href;
  let split = CurrentUrl.split("/").reverse();
  split[0] = "register";

  const refralUrl = split.reverse().join("/");

  if (a) {
    sUrl = `${refralUrl}/${a._id}`;

  }


  const FetchLink= async ()=>{
    try {
        const response = await axios.get(`${BASE_URL}/socialLinks`)
        if(response){
          setLinks(response?.data)
        }
    } catch (error) {
    }
  }

  useEffect(()=>{
    FetchLink()
    const handleScroll = () => {
      window.scrollY > 100 ? setSticky('sticky') : setSticky('');
    };
    const restoreScrollPosition = () => {
      const storedScrollPosition = localStorage.getItem('scrollPosition');
      if (storedScrollPosition) {
        window.scrollTo(0, parseInt(storedScrollPosition));
      }
    };

    window.addEventListener('scroll', handleScroll);
    restoreScrollPosition();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  },[])

  const changeLanguage = (lng) => {
    handleLanguageChange(lng);
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
    setLangSet(lng)
  };
  const storedLanguage = localStorage.getItem("language");

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language") || "English";
    handleLanguageChange(storedLanguage);
    i18n.changeLanguage(storedLanguage);
    setLangSet(storedLanguage)
  }, [i18n, handleLanguageChange]);

  const getLinkClass = (language) => {
    return langSet === language ? 'selected-language' : '';
  };
  
  const getFlagAndName = (languageCode) => {
    switch (languageCode) {
      case "Chinese":
        return {
          flag: "china.png",
          name: "Chinese",
          width: "30px",
        };
      case "Thai":
        return {
          flag: "thailand.png",
          name: "Thai",
          width: "60px",
        };
      case "English":
      default:
        return {
          flag: "united-states.png",
          name: "English",
        };
    }
  };

  const { flag, name } = getFlagAndName(storedLanguage);


  const checkUser = () => {
    fetch(`${BASE_URL}/search=${user?.name}`, {
      method: 'Post',
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    })
      .then(response => response.json())
      .then((data) => {
        if (data.user && data.user.length > 0) {
          setuserActive(true);
        } else {
          setuserActive(false);
        }
      })

      .catch(error => console.error('Error:', error));
  };
  useEffect(() => {
    checkUser();
  }, []);

  return (
    <Fragment>
      {/* Aside (Mobile Navigation) */}
      <aside className={navtoggle ? "main-aside open" : "main-aside"}>
        <div className="aside-title">
          <div className="aside-controls aside-trigger">
            <h4>Menu</h4>
            <div
              className="close-btn close-dark"

              onClick={() => setnavtoggle(false)}
            >
              <span />
              <span />
            </div>
          </div>
        </div>
        <Mobilemenu />
      </aside>
      <div
        className="aside-overlay aside-trigger"
        onClick={() => setnavtoggle(false)}
      />
      {/* Header Start */}
      <header className={`main-header header-fw can-sticky header-1 ${sticky}`}>
        {/* Top Header Start */}
        <div className="top-header">
          {user == null || !userActive ? (
            <div className="top-header-inner">
             <ul className="social-media">
              {
                Links && Links?.length>0 && Links?.map((item)=>(
                  <li>
                  <Link to={item?.link} target="_blank">
                  <img src={item?.image} className="w-[30px] h-[30px] social_icon_img"/>
                  </Link>{" "}
                </li>
                ))
                 
              }
            
            </ul>
              <ul className="top-header-nav">
                <li>
                  {" "}
                  <Link to={"/login"}>{t("login")}</Link>{" "}
                </li>
                <li>or</li>
                <li>
                  {" "}
                  <Link to={"/register"}> {t("signup")}</Link>{" "}
                </li>
              </ul>
            </div>
          ) : (
            <div className="top-header-inner">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  textAlign: "center",
                }}
              >
              <ul className="social-media">
                {
                  Links && Links?.length>0 && Links?.map((item)=>(
                    <li>
                    <Link to={item?.link} target="_blank">
                      <img src={item?.image} className="w-[30px] h-[30px] social_icon_img"/>
                    </Link>{" "}
                  </li>
                  ))
                  
                }
              
              </ul>
                <div className="d-flex ">
                  <p
                    style={{
                      backgroundColor: "white",
                      padding: "5px 10px",
                      borderRadius: "20px",
                      marginRight:"10px",
                    }}
                  >
                    {"Welcome "}{user.name}
                  </p>
                  <p
                    onClick={() => logout()}
                    style={{
                      backgroundColor: "white",
                      padding: "5px 20px",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                  >
                    {t("Log Out")}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Top Header End */}
        <nav className="navbar">
          {/* Menu */}
          <Menu />

          <div className="header-controls">
            <ul className="header-controls-inner d-none d-lg-flex">
              <li>
                <Link to="/submit-listing" className="btn-custom primary px-2">
                  {t("submitListing")} <i className="fas fa-plus" />{" "}
                </Link>
              </li>
            </ul>
            {/* Toggler */}

            <Dropdown className="ms-0 mt-0 acr-language-selector d-flex">
              <Dropdown.Toggle as={NavLink} className="dropdownicon">
                <img
                  src={process.env.PUBLIC_URL + `/assets/img/flags/${flag}`}
                  alt="flag"
                  width={30}
                />
                <span>{name}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu">
                <ul className="mb-0">
                  <Dropdown.Item href="#" onClick={() => {
                    changeLanguage("Chinese");
                  }}>
                    <li

                    >
                      {" "}
                      <Link to="#" className="d-flex">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/img/flags/china.png"
                          }
                          alt="flag"
                        />{" "}
                        <p className={`${getLinkClass('Chinese')}`}>Chinese</p>
                      </Link>{" "}
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => {
                    changeLanguage("English");
                  }}>
                    <li>
                      {" "}
                      <Link
                        to="#"
                        className="d-flex"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/flags/united-states.png"
                          }
                          alt="flag"
                        />
                        <p className={`${getLinkClass('English')}`}>English</p>
                      </Link>{" "}
                    </li>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => {
                    changeLanguage("Thai");
                  }}>
                    <li

                    >
                      {" "}
                      <Link to="#" className="d-flex">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/img/flags/thailand.png"
                          }
                          alt="flag"
                        />{" "}
                        <p className={`${getLinkClass('Thai')}`}>Thai</p>
                      </Link>{" "}
                    </li>
                  </Dropdown.Item>
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <div
              className="aside-toggler aside-trigger"
              onClick={() => setnavtoggle(true)}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
        </nav>
      </header>
      {/* Header End */}
    </Fragment >
  );
};

export default Headerfour;
