import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown, NavLink } from "react-bootstrap";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import defaultpic from "../../../assets/DefaultPic/profile.png";
import BASE_URL from "../../../baseUrl";
import { Image, Modal, Button } from "antd";
const bedstip = () => <Tooltip>Beds</Tooltip>;
const bathstip = () => <Tooltip>Bathrooms</Tooltip>;
const areatip = () => <Tooltip>Square Feet</Tooltip>;

const Toplistings = () => {
  const customeSlider = useRef();
  const { t } = useTranslation();

  const [listing, setListing] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);

  const SubmitlistingData = async () => {
    // const response = await fetch(`${BASE_URL}/submitlisting/lastsubmit`);
    const response = await fetch(`${BASE_URL}/admin/topList`);
    const data = await response.json();
    console.log(data.result);
    setListing(data.result);
  };

  useEffect(() => {
    SubmitlistingData();
  }, []);

  const next = () => {
    if (customeSlider.current) {
      customeSlider.current.slickNext();
    }
  };

  const previous = () => {
    if (customeSlider.current) {
      customeSlider.current.slickPrev();
    }
  };

  const handleBeforeChange = (oldIndex, newIndex) => {
    setCurrentSlide(newIndex);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    beforeChange: handleBeforeChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
        },
      },
    ],
  };

  return (
    <div className="section sectionlisting">
      <div className="container top-listings">
        <div className="acr-arrows" style={{ left: "40px", bottom: "-40px" }}>
          <button
            className="slider-prev fas fa-arrow-left slick-arrow"
            onClick={previous}
            disabled={currentSlide === 0}
          />
          <button
            className="slider-next fas fa-arrow-right slick-arrow"
            onClick={next}
            disabled={currentSlide >= listing.length - slidesToShow}
          />
        </div>
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">{t("trending")}</h5>
          <h2 className="title">{t("ourTopListings")}</h2>
        </div>
        <Slider
          className="top-listings-slider"
          ref={customeSlider}
          {...settings}
        >
          {listing.length == 0 ? (
            <p className="text-center my-4 text-xl font-medium">
              {t("No top list available")}
            </p>
          ) : (
            listing?.map((item, i) => (
              <div
                key={i}
                className="flex justify-between items-stretch bg-light border-black border p-2"
                style={{ height: "450px" }}
              >
                <div className="flex-grow">
                  <div
                    className="listing listing-list myBestdealofMonth justify-center"
                    style={{
                      margin: "0.5rem",
                      height: "450px",
                      border: "1px solid black",
                    }}
                  >
                    <div
                      className="listing-body bg-light flex justify-between px-2 py-2"
                      style={{ width: "100%" }}
                    >
                      <div>
                        <h5 className="listing-title mb-3 px-2 pt-3">
                          <Link
                            to={`/listing-details/${item?._id}`}
                            title={item.BasicInformation?.name}
                          >
                            {item.BasicInformation?.name}
                          </Link>
                        </h5>
                        <div className="listing-author mb-3 px-2">
                          <img
                            src={item.Gallery?.file?.[0] || defaultpic}
                            alt="author"
                            className="h-[40px] w-[70px] rounded-full"
                          />
                          <div className="listing-author-body">
                            <p>
                              <Link to="#">{item.author?.name}</Link>
                            </p>
                            <span className="listing-date">
                              {item?.createdAt?.split("T")[0]}
                            </span>
                          </div>
                        </div>
                        {
                          item?.BasicInformation?.originalprice && (
                            <span className="listing-price-left text-[10px]">
                              Original Price : {' '}
                              {Number(item?.BasicInformation?.originalprice).toLocaleString()}
                            </span>
                          )
                        }
                        <p className="listing-text">
                          {item.BasicInformation?.description?.length > 70
                            ? item.BasicInformation?.description.slice(0, 50) +
                            "..."
                            : item.BasicInformation?.description}
                        </p>
                      </div>
                      <div style={{ maxWidth: "100px", maxHeight: "100px" }}>
                        <Image
                          src={item.Gallery?.file?.[0] || defaultpic}
                          style={{
                            aspectRatio: "20/16",
                            cursor: "pointer",
                            width: "100%",
                          }}
                          className="rounded-[10px]"
                          alt="Slide"
                        />
                      </div>
                    </div>
                    <div
                      className="acr-listing-icons mb-3 px-2"
                      style={{ height: "50px", fontSize: "12px" }}
                    >
                      <OverlayTrigger overlay={bedstip}>
                        <div className="acr-listing-icon">
                          <i className="flaticon-bedroom" />
                          <span className="acr-listing-icon-value">
                            {item.Details?.beds
                              ? item.Details?.beds
                              : item.Details?.beds}
                          </span>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger overlay={bathstip}>
                        <div className="acr-listing-icon">
                          <i className="flaticon-bathroom" />
                          <span className="acr-listing-icon-value">
                            {item.Details?.bathrooms
                              ? item.Details?.bathrooms
                              : item.Details?.bathrooms}
                          </span>
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger overlay={areatip}>
                        <div className="acr-listing-icon">
                          <i className="flaticon-ruler" />
                          <span className="acr-listing-icon-value">
                            {item.BasicInformation?.space
                              ? item.BasicInformation?.space
                              : item.BasicInformation?.space}{" "}
                            SQM
                          </span>
                        </div>
                      </OverlayTrigger>
                    </div>
                    <div className="w-full text-end">
                      <p className="text-end listing-price mb-3 px-2" style={{fontSize:"25px"}}>
                        {item.BasicInformation?.listingType === "Rent" ? (
                          <>
                            Rental Price :
                            {item.BasicInformation.currency}
                            {item.BasicInformation?.rentalPrice
                              ? Number(
                                item.BasicInformation?.rentalPrice
                              ).toLocaleString()
                              : ""}
                          </>
                        ) : (
                          <>
                            Selling Price :
                            {
                              item.BasicInformation.currency +
                              (item.BasicInformation.price?.toString()
                                .length > 10
                                ? item.BasicInformation.price
                                  .toString()
                                  .slice(0, 10) + "..."
                                : Number(
                                  item.BasicInformation.price
                                ).toLocaleString())}
                          </>
                        )}
                      </p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div className="listing-gallery-wrapper mb-0 px-2">
                        <Link
                          to={`/listing-details/${item._id}`}
                          className="btn-custom btn-sm secondary mb-2"
                        >
                          {t("viewDetails")}
                        </Link>
                      </div>
                      <div>
                        <span
                          className="acr-listing-icon-value text-[12px]"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          {item.Location.country === "undefined" ||
                            item.Location.country === ""
                            ? "Country: Thailand "
                            : item?.Location?.country
                              ? "Country : " + item.Location?.country
                              : ""}
                        </span>
                        <span
                          className="acr-listing-icon-value text-[12px]"
                          style={{ fontWeight: "bold", color: "black" }}
                        >
                          {item?.Location?.provice === "undefined" ||
                            item?.Location?.provice === ""
                            ? "Province: Bangkok "
                            : item?.Location?.provice
                              ? "Province :" + item?.Location?.provice
                              : "Province: Bangkok "}
                          {""}
                          {item?.Location?.city === "undefined" ||
                            item?.Location.city === ""
                            ? "/City: Bangkok "
                            : item?.Location?.city
                              ? "/City :" + item?.Location?.city
                              : "/City: Bangkok "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Slider>
      </div>
    </div>
  );
};

export default Toplistings;
