// import axios from 'axios';
import React, { useEffect, useState } from "react";
// import { OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AgentListingFilter from "../../pages/Agents/AgentlistingFilter";
import BASE_URL from "../../../baseUrl";
import axios from "axios";

function Content() {
  const [state, setstate] = useState([]);
  const [SearhingData, setSearhingData] = useState();
  const [Data, setdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userDetails, setUserDetails] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const getAgent = async () => {
    const response = await fetch(`${BASE_URL}/agent/get-agents`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
    });
    const data = await response.json();
    setstate(data?.result);
  };
  const itemsPerPage = 5;
  const totalPages = Math.ceil(state.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = state.slice(startIndex, startIndex + itemsPerPage);
  useEffect(() => {
    const getuser = localStorage.getItem("userInfo");
    if (getuser) {
      const userInfo = JSON.parse(getuser);
      setUserDetails(userInfo?._id);
    }
  }, []);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const searchAgent = async () => {
    const params = {
      city: Data.city,
      country: Data.country,
      states: Data.states,
    };

    const url = `${BASE_URL}/agent/agent-search`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors",
      },
      body: JSON.stringify(params),
    });
    const data = await response.json();
    setstate(data?.result);
  };

  useEffect(() => {
    if (SearhingData) {
      setdata(SearhingData);
    }
  }, [SearhingData]);

  useEffect(() => {
    getAgent();
  }, []);

  const EditAgent = (id) => {
    if (id) {
      navigate(`/AgentCreate`, { state: id });
    }
  };
  const DeleteAgent = (id) => {
    try {
      const response = axios.delete(`${BASE_URL}/Agent/delete/${id}`);
      if (response) {
        getAgent();
      }
    } catch (error) {}
  };
  return (
    <>
      <AgentListingFilter
        getData={searchAgent}
        setSearhingData={setSearhingData}
      />
      <div className="container">
        <div className="flex">
          <div className="w-100 my-8">
            {currentData?.map((res) => {
              const basicInformation = res?.BasicInformation;
              const author = res?.Author;
              return (
                <div className="listing listing-list ">
                  <div
                    className="listing-thumbnail"
                    style={{
                      width: "250px",
                      height: "200px",
                      // overflow: "hidden",
                      // position: "relative",
                    }}
                  >
                    <Link>
                      <img
                        src={`${res?.imageUrl}`}
                        alt="listing"
                        style={{
                          width: "100%", // Make the image take the full width of the container
                          height: "100%", // Make the image take the full height of the container
                        }}
                      />
                    </Link>
                  </div>

                  <div className="listing-body" style={{ width: "70%" }}>
                    <div>
                      <div className="listing-author">
                        <div className="listing-author-body">
                          <p>
                            {" "}
                            <Link to="#">{author?.authorname}</Link>{" "}
                          </p>
                          <span className="listing-date">
                            {res?.name + " " + res?.lastname}
                          </span>
                        </div>
                      </div>
                      <h5 className="listing-title">
                        {" "}
                        <Link
                          onClick={() => {
                            navigate(`/listing-details/${res._id}`);
                            window.location.reload(false);
                          }}
                        >
                          {basicInformation?.name}
                        </Link>{" "}
                      </h5>
                      <span className="listing-price">
                        <b>{t("Real Estate Type")}</b>:{" "}
                        <span>
                          {res?.realEstateType?.map((item, index) => (
                            <span
                              key={index}
                              style={{ marginRight: "8px", fontSize: "20px" }}
                            >
                              {item}
                            </span>
                          ))}
                        </span>
                      </span>

                      <div className="acr-listing-icons">
                        <div className="">
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("Name")}</b>:
                            </p>
                            <span className="">{res?.name}</span>
                          </div>
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("Tel")}</b>:
                            </p>
                            <span className="">
                              {res?.tel ? res?.tel : "---"}
                            </span>
                          </div>
                        </div>
                        <div className="phonenumber">
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("lastname")}</b>:
                            </p>
                            <span className="">
                              {res?.lastname ? res?.lastname : "---"}
                            </span>
                          </div>
                          <div
                            style={{ fontSize: 12 }}
                            className="acr-listing-icon"
                          >
                            <p className="acr-listing-icon-value  pe-2">
                              <b>{t("lineID")}</b>:
                            </p>
                            <span className="">{res?.lineId}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mb-4">
          {currentData.length === 0 ? null : (
            <nav aria-label="...">
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                </li>
                {[...Array(totalPages)].map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </>
  );
}

export default Content;
