import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Heading,
  VStack,
  useToast,
  Input,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BASE_URL from "../baseUrl";
import axios from "axios";

const ContentAgent = () => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    tel: "",
    whatApps: "",
    weChat: "",
    line: "",
    comment: "",
  });

  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle phone input changes based on the field name (tel, whatsapp, wechat, line)
  const handlePhoneChange = (value, field) => {
    setFormData({
      ...formData,
      [field]: value, // Dynamically update the phone fields
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();  // Prevent the default form submission behavior immediately
    console.log(formData);
  
    try {
      const response = await axios.post(`${BASE_URL}/becomeOurAgent`, formData);
  
      // Check the response status properly (axios returns status codes, not `ok`)
      if (response.status === 200 || response.status === 201) {
        toast({
          title: "Form submitted successfully!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
  
        // Clear form fields after successful submission
        setFormData({
          name: "",
          lastname: "",
          tel: "",
          whatApps: "",
          weChat: "",
          line: "",
          comment: "",
        });
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      toast({
        title: "Error submitting form",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  

  return (
    <div className="section sm:mt-[280px] mb-5">
      <Box
        maxW="600px"
        mx="auto"
        mt="50px"
        p="8"
        boxShadow="lg"
        rounded="md"
        bg="white"
      >
        <Heading as="h1" size="lg" textAlign="center" mb="6">
          Become our Agent
        </Heading>

        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                name="firstname"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Lastname</FormLabel>
              <Input
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                placeholder="Enter your lastname"
              />
            </FormControl>

            {/* Telephone Field */}
            <FormControl isRequired>
              <FormLabel>Telephone</FormLabel>
              <PhoneInput
                country={"th"}
                value={formData.tel}
                onChange={(value) => handlePhoneChange(value, "tel")}
                inputStyle={{ width: "100%" }}
                placeholder="Enter your phone number"
                name="tel"
              />
            </FormControl>

            {/* WhatsApp Field */}
            <FormControl isRequired>
              <FormLabel>WhatsApp</FormLabel>
              <PhoneInput
                country={"th"}
                value={formData.whatApps}
                onChange={(value) => handlePhoneChange(value, "whatApps")}
                inputStyle={{ width: "100%" }}
                placeholder="Enter your WhatsApp number"
                name="whatApps"
              />
            </FormControl>

            {/* WeChat Field */}
            <FormControl>
              <FormLabel>Wechat</FormLabel>
              <PhoneInput
                country={"th"}
                value={formData.weChat}
                onChange={(value) => handlePhoneChange(value, "weChat")}
                inputStyle={{ width: "100%" }}
                placeholder="Enter your WeChat ID"
                name="weChat"
              />
            </FormControl>

            {/* Line Field */}
            <FormControl>
              <FormLabel>Line</FormLabel>
              <PhoneInput
                country={"th"}
                value={formData.line}
                onChange={(value) => handlePhoneChange(value, "line")}
                inputStyle={{ width: "100%" }}
                placeholder="Enter your Line ID"
                name="line"
              />
            </FormControl>

            {/* Comment Field */}
            <FormControl>
              <FormLabel>Comment</FormLabel>
              <Textarea
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                placeholder="Enter your comments"
              />
            </FormControl>

            <Button colorScheme="teal" type="submit" width="full">
              Submit
            </Button>
          </VStack>
        </form>
      </Box>
    </div>
  );
};

export default ContentAgent;
