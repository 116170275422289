import React from "react";
import ListingFilter from "../pricing/ListingFilter";
import { useTranslation } from "react-i18next";
import homeBanner from "../../../assets/img/homeBanner.jpeg"
const Banner = () => {
  
  const { t } = useTranslation();
  const filterData = (data) => {
  };

  return (
    <div
  className="banner banner-1 banner-3 dark-overlay bg-cover bg-center"
  style={{
    backgroundImage: `url(${process.env.PUBLIC_URL}${homeBanner})`,
  }}
>

      <div className="container">
        <div className="banner-item ">
        <div className="banner-inner text-center">
      <h1 className="title text-white">{t("moveOnlineTitle")}</h1>
      <h3 className="title text-white">{t("moveOnlineTitle2")}</h3>
      <h4 className="text-white">{t("searchDescription")}</h4>
      
      <ListingFilter getData={filterData} />
    </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
