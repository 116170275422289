import React, { useState, useReducer, useEffect } from "react";
import Data from "../../../data/select";
import RentReducer from "../../../reducers/RentReducer";
import { useTranslation } from "react-i18next";

var RentFilter = {};

const CatListingFilter = ({ getData, setSearhingData,onClick }) => {
  const [advanceSearch, setAdvanceSearch] = useState(true);
  const { t } = useTranslation()
  const {
    locationlist,
    statuslist,
    pricerangelist,
    bedslist,
    bathroomslist,
    diameterlist,
  } = Data;

  const [RentState, rentDispatch] = useReducer(RentReducer, RentFilter);
  const filterData = () => {
    RentFilter = {
      Pricerange: RentState.Pricerange,
      Propertytype: RentState.Propertytype === "Any Property" ? "" : RentState.Propertytype,
      Propertylocation: RentState.Propertylocation,
      tumbol: RentState.tumbol,
      city: RentState.city,
      state: RentState.state,
      country: RentState.country,
    };
    getData(RentFilter);
  };
  useEffect(() => {
    if(RentState){
    
      setSearhingData(RentState)
    }
  }, [RentState]);

  
  
 

  return (
    <div className="mt-8 mb-16">
      <div className="flex justify-center">
        <div className="acr-filter-form w-11/12 sm:w-9/12 min-md:w-8/12 md:9-12">
          <div className="border border-gray px-4 pt-4 pb-2 rounded-xl">
            <div className="row ">
            <div className="col-lg-3 col-md-6">
                <div className="">
                  <select
                    className="form-control"
                    placeholder={t("Price Range")}
                    name=""
                    style={{ fontWeight: "bold", marginBottom: "15px" }}
                    value={RentState.Pricerange}
                    onChange={(e) =>
                      rentDispatch({
                        type: "UPDATE",
                        value: e.target.value,
                        key: "Pricerange",
                      })
                    }
                  >
                    <option  className="form-control" value="Price range" >
                      Price Range
                        </option>
                        <option  className="form-control" value="434" >
                          $434
                        </option>
                        <option  className="form-control" value="34" >
                          $34
                        </option>
                        <option  className="form-control" value="22" >
                          $22
                        </option>
                  </select>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div>
                  <select
                    className="form-control"
                    style={{ fontWeight: "bold", marginBottom: "15px" }}

                    value={RentState.Propertytype}
                    onChange={(e) =>
                      rentDispatch({
                        type: "UPDATE",
                        value: e.target.value,
                        key: "Propertytype",
                      })
                    }
                  >
                    <option value="" hidden>
                    {t("PropertyType")}
                    </option>
                      return ( */}
                      <option  className="form-control" value="Any Property" >
                      Any Property
                        </option>
                        <option  className="form-control" value="Residence/Vill" >
                          Residence/Vill
                        </option>
                        <option  className="form-control" value="Condo" >
                          Condo
                        </option>
                        <option  className="form-control" value="Apartment" >
                          Apartment
                        </option>
                        <option  className="form-control" value="Building" >
                          Building
                        </option>
                        <option  className="form-control" value="Resort" >
                          Resort
                        </option>
                        <option  className="form-control" value="Land" >
                          Land
                        </option>
                  </select>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
              <div>
                  <input
                    className="form-control"
                    name="Status"
                    placeholder={t("City/Amphor")}
                    style={{ fontWeight: "bold", marginBottom: "15px" }}
                    value={RentState.city}
                    onChange={(e) =>
                      rentDispatch({
                        type: "UPDATE",
                        value: e.target.value,
                        key: "city",
                      })
                    }
                  >
                  </input>
                </div>
               </div>
              <div className="col-lg-3 col-md-6">
              <div className="col-lg-2 col-md-6">
              
              </div>
                <div>
                  <input
                    className="form-control"
                    name="Status"
                    placeholder={t("States/Province")}

                    style={{ fontWeight: "bold", marginBottom: "15px" }}
                    value={RentState.state}
                    onChange={(e) =>
                      rentDispatch({
                        type: "UPDATE",
                        value: e.target.value,
                        key: "state",
                      })
                    }
                  >
                  </input>
                </div>
              </div>
              <div className="submit-btn col-lg-2 col-md-6">
                <div className="form-group">
                  <button
                    onClick={() => filterData()}
                    className="btn-custom secondary btn-block"
                  >
                    {t("Search listings")}
                  </button>
                </div>
              </div>
            </div>
            <div className={advanceSearch ? "advanced-search" : "d-block"}>
              <div className="row">
              <div className="col-lg-2 col-md-6">
                <div>
                  <input
                    className="form-control"
                    name="country_label"
                    placeholder={t("country_label")}

                    style={{ fontWeight: "bold", marginBottom: "15px" }}
                    value={RentState.country}
                    onChange={(e) =>
                      rentDispatch({
                        type: "UPDATE",
                        value: e.target.value,
                        key: "country",
                      })
                    }
                  >
                  </input>
                </div>
              </div>
              
                
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div
              className={
                advanceSearch
                  ? "advanced-search-trigger semi-circle active"
                  : "advanced-search-trigger semi-circle"
              }
              onClick={() => setAdvanceSearch(!advanceSearch)}
            >
              <i className="fas fa-chevron-up" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CatListingFilter;
