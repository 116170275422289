import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/ThinkToKnow/PropertyContent";
import Download from "../layouts/App";
import BsInfo from "../sections/services/Bsinfo";
import { useTranslation } from "react-i18next";

const Property = () => {
  const { t } = useTranslation();

  let data = {
    title: t("How to buy poperty in Thailand"),
    para:
      t("Buying property in Thailand"),
    picture: "/assets/img/listings-list/propertyInThailand.jpg"
  }

  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | How to buy property in Thailand</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb
        breadcrumb={{ pagename: t("How to buy poperty in Thailand") }}
      />

      <BsInfo data={data} />
      <Download />
      <Footer />
    </Fragment>
  );
};

export default Property;
