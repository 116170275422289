import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import footer from "../../assets/img/logoFooter.png"
import { useTranslation } from "react-i18next";
import BASE_URL from "../../baseUrl";
import { useToast } from "@chakra-ui/react";
const Footercontent = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [Links, setLinks] = useState("");
  const { t } = useTranslation();
  const toast = useToast();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/Footer/get-footertext`);
        if (response.data.result) {
          setName(response.data?.result[0]?.title)
          setDescription(response.data?.result[0]?.content)
        }
      } catch (error) {
        // console.error('Error fetching data:', error.message);
      }
    };

    fetchData();
  }, []);

  const sendEmail = () => {
    if (email) {
      axios
        .post(`${BASE_URL}/SEO/subscription`, {
          email: email
        }).then((res) => {
          toast({
            title: "Success",
            description: res?.data?.message ? res?.data?.message : "Email sent successfully.",
            status: "success",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });

        }).catch((e) => {
          toast({
            title: "Error",
            description: e?.response?.data?.message ? e?.response?.data?.message : "You Already Subscribed Us",
            status: "error",
            duration: 2000,
            variant: "left-accent",
            position: "top-right",
            isClosable: true,
          });
        })
    }

  }

  const FetchLink= async ()=>{
    try {
        const response = await axios.get(`${BASE_URL}/socialLinks`)
        if(response){
          setLinks(response?.data)
        }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    FetchLink()
  },[])






  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-12 footer-widget">
            <div className="footer-logo">
              <h1 className="text-white text-[32px] 32px leading-[35px]	">
                {t("foothead")}
              </h1>

              <img src={footer} alt="acres" />
            </div>

            <ul className="social-media">
              {Links &&
                Links?.length > 0 &&
                Links?.map((item) => (
                  <li>
                    <Link to={item?.link} target="_blank">
                      <img src={item?.image} />
                    </Link>{" "}
                  </li>
                ))}
            </ul>
          </div>
          <div className="col-lg-2 offset-md-1 col-md-4 col-sm-6 footer-widget">
            <h5 className="widget-title">{t("menu")}</h5>
            <ul>
              <li>
                <Link to="/rental-guideline">{t("Rental Guideline")}</Link>
              </li>
              <li>
                <Link to="/Becomeouragent">{t("become_our_agent")}</Link>
              </li>
              <li>
                <Link to="/Complain">{t("complaint")}</Link>
              </li>
              <li>
                <Link to="/blog">{t("blogs_news")}</Link>
              </li>
              <li>
                <Link to="/sellustous">{t("sell_property")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 footer-widget">
            <h5 className="widget-title">{t("legal")}</h5>
            <ul>
              <li>
                <Link to="/privacypolicy">{t("privacy_policy")}</Link>
              </li>
              <li>
                <Link to="/termsandcond">{t("terms_condition")}</Link>
              </li>
              <li>
                <Link to="/sell/agent">{t("find_agent_worldwide")}</Link>
              </li>
              <li>
                <Link to="/contact">{t("contact_us")}</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-12 footer-widget">
            <h5 className="widget-title">{t("newsletter")}</h5>
            <p>{t("newsletter_description")}</p>
            <form method="post">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("newsletter_placeholder")}
                  name="newsletter-email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </form>
            <button
              onClick={() => sendEmail()}
              className="btn-custom primary light btn-block"
            >
              {t("join_newsletter")}
            </button>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <p className="m-0">{t(name)}</p>
            </div>
            <div className="col-lg-6">
              <p className="text-center">{t(description)}</p>
            </div>
            <div className="col-lg-3">
              <ul>
                <li>
                  {" "}
                  <Link to="/">{t("find_home")}</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/submit-listing">{t("add_listing")}</Link>{" "}
                </li>

                <li>
                  {" "}
                  <Link to="/sell/agent">{t("View Agencies")}</Link>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footercontent;
