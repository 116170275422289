/////////////////////     Functional Component ////////////////////////////
import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/submit-listing/Content";
import { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Submitlisting = () => {
  const {t} = useTranslation();
  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Submit Listing</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("submitListing") }} />
      <Content />
      <Footer />
    </Fragment>
  );
};

export default Submitlisting;
