import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/services/Content";
import { useTranslation } from "react-i18next";

const Services = () => {
  const {t} = useTranslation();
  
  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Services</title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb breadcrumb={{ pagename: t("Services") }} />
      <Content />
      <Footer />
    </Fragment>
  );
};

export default Services;
