import React, { useState } from "react";
import { Link } from "react-router-dom";
import serviceblock from "../../../data/services.json";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();
  const [readMore, setReadMore] = useState({});

  const handleReadMore = (index) => {
    setReadMore((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the state for the clicked item
    }));
  };
  return (
    <div
      className="pb-5 pt-3 light-bg infographics-2 bg-norepeat bg-bottom"
      style={{
        backgroundImage:
          "url(" + process.env.PUBLIC_URL + "/assets/img/misc/bldg.png )",
      }}
    >
      <div className="container">
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">{t("Services")}</h5>
          <h2 className="title">{t("need")}</h2>
        </div>
        <div className="row">
          {serviceblock.map((item, i) => (
            <div key={i} className="col-lg-4 col-md-6">
              <div className="bg-white mb-2 px-4 py-4 rounded-[10px] text-center myabout">
              <i className={"flaticon-" + item.icon} 
                style={{
                  width:"100px",
                  fontSize:"36px",
                  color:"#01bbbc"
                }}
              />
                <h5>{t(item.title)}</h5>

                <p
                className="mb-4"
                  style={{
                    // whiteSpace: readMore[i] ? "normal" : "nowrap",
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                  }}
                >
                  {readMore[i] ? item.text : item.text.slice(0, 40) + "..."}
                </p>

                {item.text && item.text.length > 40 && (
                  <Link to="#" onClick={() => handleReadMore(i)} className="bg-[#01bbbc] text-white px-4 py-2 rounded-[10px]">
                    <span>{t(readMore[i] ? "Show Less " : "Read More ")}</span>
                    <i className={`fas ${readMore[i] ? 'fa-chevron-up' : 'fa-chevron-right'}`} />
                  </Link>
                )}

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
