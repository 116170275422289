import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate, useNavigation, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { Button } from "react-bootstrap";
import { t } from "i18next";
// import { useParams } from "react-router-dom";
import BASE_URL from "../../baseUrl";

const Menu = () => {
  const navig = useNavigate();
  const [mainLists, setMainLists] = useState([]);
  const [statuslist, setStatuslist] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedMainPage, setSelectedMainPage] = useState("");
  const params = useLocation();
  const fetchMainLists = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/NavList/api/mainlists`);
      const data = response?.data.sort((a, b) => a.index - b.index);

      setMainLists(data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchMainLists();
    axios
      .get(`${BASE_URL}/admin/get-categories`)
      .then((res) => {
        setStatuslist(res.data.result);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    const decodePathname = decodeURIComponent(params.pathname);
    const matchedMainList = mainLists.find((mainList) => {
      const mainPagePath = `/${mainPages[mainList.title]}`;
      return decodePathname.startsWith(mainPagePath);
    });

    if (matchedMainList) {
      setSelectedMainPage(matchedMainList._id);
    } else {
      // Check subPages
      mainLists.forEach((mainList) => {
        mainList.subLists.forEach((subList) => {
          const subPagePath = `/${subPages[subList.title]}/${mainList.title}`;
          if (decodePathname.startsWith(subPagePath)) {
            setSelectedMainPage(mainList._id);
          }
        });
      });
    }
  }, [params.pathname, mainLists]);

  const navigBtn = (e) => {
    if (e.title == "Home") {
      navig("/home");
    }

    if (e.title == "About Us") {
      navig("/about");
    }
    if (e.title == "Services") {
      navig("/services");
    }
  };

  let mainPages = {
    Home: "home",
    "About Us": "about",
    Services: "services",
    Rent: "rent",
    sell: "sell",
    Buy: "buy",
    "Thing To Know": "Think-to-know",
    "Own Property in Thailand Laws":"Own-Property-in-Thailand-Laws"
  };

  let subPages = {
    "Help With Distress Home": "Distress",
    "Financial Planing": "Planing",
    "Loan Modification": "Modification",
    "Property Management": "Management",
    "Investment Planner": "Planner",
    "How to buy poperty in Thailand": "Property",
    "Get Long Term Visa in Thailand": "Terms",
    "Thailand Real estate Laws": "Estate",
    "How to retire in Thailand": "Retire",
    Condo: "property/Condo",
    Commercial: "property/Commercial",
    Residential: "property/Residential",
    Apartment: "property/Apartment",
    Flats:"property/Flats",
    Land: "property/Land",
    Villa: "property/Villa",
    Island: "property/Island",
    Resort: "property/Resort",
    Hotel: "property/Hotel",
    "Residential/Condo/Apartment": "property/Residential/Condo/Apartment",
    "Warehouse & Factories": "property/Warehouse%20&%20Factories",
    Retails: "property/Retails",
  };

  const handleMainPageClick = (mainPage) => {
    setSelectedMainPage(mainPage);
  };

  return (
    <Fragment>
      <Link
        className="navbar-brand"
        to="/"
        onClick={() => window.location.replace("/")}
      >
        {" "}
        <img
          className="p-2"
          src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
          alt="logo"
        />{" "}
      </Link>
      <ul id="menu_none" className="menu_none pt-4">
        {mainLists.map((mainList) => (
          <li
            key={mainList._id}
            className={`menu-item menu-item-has-children items-center ${selectedMainPage === mainList._id ? 'selected' : ''}`}
          >
            <div className="dropdown-wrapper">
              <Link
                to={`/${mainPages[mainList.title]}`}
                className="dropdown-trigger"
                onMouseEnter={() => setShowDropdown(mainList._id)}
                onClick={() => handleMainPageClick(mainList._id)}
                style={{ color: selectedMainPage === mainList._id ? '#519FFF' : '', backgroundColor: "white" }}
              >
                {t(mainList.title)}
              </Link>
              {showDropdown === mainList._id &&
                mainList.subLists.length > 0 && (
                  <div className="dropdown-menu">
                    {mainList.subLists.map((subList) => (
                     <>
                      <Link
                        key={subList._id}
                        className="dropdown-item"
                        style={{borderBottom:"2px solid rgb(137 137 137)"}}
                        to={`/${subPages[subList.title]}/${mainList.title}`}
                      >
                        {t(subList.title)}
                      </Link>
                     </>
                    ))}
                  </div>
                )}
            </div>
          </li>
        ))}

      </ul>

    </Fragment>
  );
};

export default Menu;
