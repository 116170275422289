import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const App = () => {
  const { t } = useTranslation();

  return (
    <div className="footer-top">
    </div>
  );
};

export default App;
