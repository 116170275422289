import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../layouts/Headerfour";
import Breadcrumb from "../layouts/Breadcrumb";
import Footer from "../layouts/Footerthree";
import Content from "../sections/ThinkToKnow/ComplainContent";
import Download from "../layouts/App";
import { useTranslation } from "react-i18next";

const Job = () => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <Helmet>
        <title>
          Buy Home For Less | Job
        </title>
        <meta name="description" content="#" />
      </Helmet>
      <Header />
      <Breadcrumb
        breadcrumb={{ pagename: t("Job") }}
      />

      <Content />
      <Download />
      <Footer />
    </Fragment>
  );
};

export default Job;
