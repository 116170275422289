import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

import Content from './Content'
import Headerfour from '../../layouts/Headerfour'



function Forgot() {
  return (
    <Fragment>
      <Helmet>
        <title>Buy Home For Less | Forgot</title>
        <meta name="description" content="#" />
      </Helmet>
      <Headerfour />
        <Content />
  
    </Fragment>
  )
}

export default Forgot
