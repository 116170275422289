import React, { useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import agents from "../../../data/agents";

const Agentsslider = () => {
  const custome = useRef();

  const next = () => {
    custome.current.slickNext();
  };
  const previous = () => {
    custome.current.slickPrev();
  };

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div></div>
  );
};

export default Agentsslider;
