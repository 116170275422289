import React from "react";
import Banner from "./Banner";
import Categories from "./Categories";
import Blockcta from "../../layouts/Blockcta";
import Whyus from "../homefour/Whyus";
import Toplistings from "../home/Toplistings";
import Recentlistings from "./Recentlistings";
import Latestblog from "../home/Latestblog";
import Faqs from "./Faqs";
import Agentsslider from "../home/Agentsslider";
import Bluecta from "../../layouts/Bluecta";
import Testimonials from "../home/Testimonials";
import Calculator from "../../layouts/Calculator";
import { useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../baseUrl";

const Content = () => {
  const { t } = useTranslation();


  useEffect(() => {
    axios
      .get(`${BASE_URL}/admin/popup`)
      .then((res) => {
        const data = res.data.data;
        const fileType = data.fileType; // Assuming your backend returns fileType (image/video)
        const fileUrl = data.file; // URL of the file
          let htmlContent = "";
    
          if (fileType.startsWith("image/")) {
            htmlContent = `<img src="${fileUrl}" alt="Promotion" style="width: 100%; height: 100%;" />`;
          } else if (fileType.startsWith("video/")) {
            htmlContent = `
            <div style="position:relative; width: 100%; height: 100%; object-fit: cover;">
            <video controls style="width: 100%; height: 100%; object-fit: cover;">
              <source src="${fileUrl}" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>`;
          }
    
          Swal.fire({
            customClass: {
              confirmButton: 'custom-confirm-button',
              popup: 'custom-popup',
            },
            html: htmlContent,
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }, []);
    

  return (
    <div>
      <Banner />
      <div className="marginHomeContent">
        <Categories />
        <div className="section section-padding pt-0"></div>
        <div className="w-100">
          <div className="container Advertisement-img"></div>
        </div>
        <div className="acr-footer footer-2">
          <Whyus />
        </div>

        <Recentlistings />
        <Blockcta />
        <Toplistings />
        <Latestblog />
        <Testimonials />
        <div className="section pt-0 pb-3">
          <Faqs />
        </div>

        <Agentsslider />
        <div className="pb-5 pt-0">
          <Bluecta />
        </div>
        <div className="container">
          <div className="col-12 myclass">
            <div className="w-full md:w-1/2">
              <h5>{t("Mortgage Calculator")}</h5>
              <Calculator />
            </div>
            <div className="myImages hidden md:block">
              <img
                className="mortgageImage"
                src="http://androthemes.com/themes/react/acres/assets/img/blog/2.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Content;
