import React from "react";
import { useTranslation } from "react-i18next";

function RentalguidelineContent() {
  const { t } = useTranslation();
  return (
    <div>
      <h3 className="text-center font-bold my-3">
        {t("LEASE OR RENTAL GUIDELINE")}
      </h3>
      <div className="flex justify-center container">
        <table className="border-2 w-[100%] my-4" border={2}>
          <tr>
            <th>{t('Lease Period')}</th>
            <td>
              <ul>
                <li>• {t("Most properties are for long-term (1 year+)")}</li>
                <li>
                  • {t(`Signing a 2-year contract upfront helps to negotiate better conditions`)}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>{t("Booking Fee (Optional)")}</th>
            <td>
              <ul>
                <li>
                  • {t(`In order to book the property before signing the agreement,a fee can be offered to the landlord (up to 1 month of rent)`)}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>{t('Security Deposit')}</th>
            <td>
              <ul>
                <li>• {t('Equivalent to 2 months of rent')}</li>
                <li>
                  • {t('Deposit put down at the signing of the lease agreement')}
                </li>
                <li>
                  • {t('Refunded when the tenant moves out minus potential repair and unpaid bills')}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>{t('Rental Payment')}</th>
            <td>
              <ul>
                <li>
                  • {t('At the beginning of each monthly period before the 5th of each month, generally by bank transfer')}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>{t('Furniture')}</th>
            <td>
              <ul>
                <li>
                  • {t('Properties for rent in Bangkok are generally fully furnished')}
                </li>
                <li>
                  • {t('Condos up to 2 bedrooms and apartments are always fully furnished')}
                </li>
                <li>
                  • {t('Condos and houses of 3 bedrooms (or more) may be unfurnished')}
                </li>
                <li>
                  • {t('Requests to remove or add furniture are treated case-by-case')}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th> {t('Inventory List')}</th>
            <td>
              <ul>
                <li>• {t('Attached to lease agreement')}</li>
                <li>
                  • {t("The check-in inventory is completed on or before the move-in   date")}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th> {t('Renovation')}</th>
            <td>
              <ul>
                <li>• {t("Specific requests are addressed case-by-case")}</li>
                <li>
                  • {t(`It is not unusual to request to repaint the premises or repair a few things`)}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th> {t("Utility Bills")}</th>
            <td>
              {" "}
              <ul>
                <li>
                  • {t(`Electricity, water, internet, cable TV are usually paid to the service provider (exception for some apartment buildings)`)}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th>{t('Diplomatic Clause')}</th>
            <td>
              <ul>
                <li>
                  • {t(`There is no general rule, some landlords accept it and some don't`)}
                </li>
                <li>
                  • {t('Generally accepted in apartment buildings, more difficult  with individual condo owners')}
                </li>
                <li>
                  • {t('Generally applicable once completed at least 12 months of rent')}
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th> {t(`Agent Commission *`)}</th>
            <td>
              <ul>
                <li>• {t('Our brokerage service is free and non-binding for the tenant')}</li>
                <li>
                  • {t('We receive a commission from the landlord when the lease agreement is signed')}
                </li>
                <li>
                  • {t("There is no charge for property hunting services offered by our agency")}
                </li>
                <li>
                  • {t(`In case of specific services requested that are not directly related to property search, e.g.moving furniture, a fee may be applied by our agency (to be discussed)`)}
                </li>
                <li>
                  • 	{t("Market practice is 1 month for the first year of rent and 1/2 month in case of extension")}
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}

export default RentalguidelineContent;
